import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Image from "../elements/Image";
import VideoDuration from "../elements/VideoDuration";

const CourseItem = ({ item = {} }) => {
  const user = useSelector((state) => state.auth.user);
  const activeLang = useSelector((state) => state.lang.activeLang);

  const redirectLink = user?._id
    ? `/course-details-adv/${item._id}`
    : `/course-details/${item._id}`;

  return (
    <div className="col-sm-6 col-lg-4 col-xl-3 course-details-sec-item">
      <div className="card shadow h-100">
        <div className="w-100" style={{ height: "190px" }}>
          <Image
            src={item.image}
            alt={item.title ? item.title[activeLang] || "" : ""}
            width={"100%"}
            height={"100%"}
            effect={"blur"}
            classes={"card-img-top"}
          />
        </div>
        <div className="card-body pb-0 d-flex flex-column">
          <div className="d-flex justify-content-between mb-2">
            {item.tags?.map((itemTag) => (
              <span
                className="badge bg-purple bg-opacity-10 text-purple"
                key={itemTag._id}
              >
                {itemTag.tagName}
              </span>
            ))}
          </div>
            {
              item.coming_soon &&
              <button 
                className="btn bg-success text-white w-100" 
                style={{ cursor: 'default', marginTop:"-45px"}}
              >
                Peagi lisandumas
              </button>
            }
          <h5 className="card-title fw-normal text-truncate-3">
            
            <Link to={redirectLink}
              style={{
                cursor: !item.coming_soon ? 'pointer' : 'not-allowed'
              }}
              onClick={(e) => {
                if (item.coming_soon) {
                  e.preventDefault()
                }
              }}
            >
              {item.title ? item.title[activeLang] || "" : ""}
            </Link>
          </h5>
          <p className="mb-2 text-truncate-2">
            {item.sub_title ? item.sub_title[activeLang] || "" : ""}
          </p>
          <div className="price d-flex gap-2 align-items-center mt-auto">
            {item?.price > 0 && (
              <div className="fw-bold text-black">
                <span>€</span>
                <span>
                  {
                    !item.discount ?
                    item.price :
                    item?.price - (item?.discount * item?.price) / 100
                  }
                </span>
              </div>
            )}
            {item?.price > 0 && item?.discount > 0 && (
              <div>
                <s>
                  <span>€</span>
                  <span>{item?.price}</span>
                </s>
              </div>
            )}
            {item?.discount > 0 && (
              <div>
                <span className="badge badge-warning py-1">
                  <span>-{item?.discount}</span>
                  <span>%</span>
                </span>
              </div>
            )}
          </div>
        </div>
        {
          !item.coming_soon &&
          <div className="card-footer pt-0 pb-3">
            <hr />
            <div className="d-flex justify-content-between">
              <span className="h6 fw-light mb-0 me-auto">
                <i className="far fa-clock text-danger me-2"></i>
                <VideoDuration ids={item.videos.map((video) => video._id)} />
              </span>
              <span className="h6 fw-light mb-0 ms-auto">
                <i className="fas fa-table text-orange me-2"></i>
                {item.video_count +
                  ` ${item.video_count === 1 ? "Video" : "Videot"}`}
              </span>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default CourseItem;
