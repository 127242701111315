import React, { useState } from 'react';
import Image from '../components/elements/Image';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import toastr from "toastr";
import { postData } from '../services/api';


const FrontFooter = () => {
  const activeLang = useSelector(state => state.lang.activeLang)
  const staticData = useSelector((state) => state.langActionStaticData.staticSiteData)
  const siteData = useSelector(state => state.siteSettings.data)
  const [emailData, setEmailData] = useState()
  const [isUpdate,setIsUpdate] = useState(false);


  const onSubmit = async (e) => {
    e.preventDefault()
    setIsUpdate(true);
    try {      
      const userData = await postData("newsletter/send", {
        email: emailData
      });
      if (userData.status && userData.status === 200) {
        setIsUpdate(false);
        setEmailData('')
        toastr.success();
      } else {
        toastr.error();
        setIsUpdate(false);
      }
    } catch (error) {
      setIsUpdate(false);
      toastr.error(error.message);
    }
  }

  // footer logo save in local storage =========>
  if (siteData?.logo) {
    localStorage.setItem("siteLogo", new URL(siteData?.logo).href);
  }
  const siteLogo = localStorage.getItem("siteLogo");

  return (

    <>
      <footer>
        <div className="container">
          <div className="row g-4 justify-content-between pt-5">
            <div className="col-md-5 col-lg-4">
              <div className="d-flex">
                <Link to={'/'}>
                  <Image
                   src={!siteData?.logo ? siteLogo : siteData?.logo} 
                   alt={'logo'} 
                   width={'100%'} 
                   height={'100%'} 
                   effect={'blur'} 
                   classes={'light-mode-item h-40px w-auto'} />
                </Link>
              </div>
              {/* <p className="my-3">Want to get notified about regulatory updated in the radiology or new trainings? Subscribe to our newsletter.</p> */}
              {/* <form className="row row-cols-lg-auto g-2">
                <div className="col-12">
                  <input type="email" className="form-control" value={emailData} onChange={(e) => setEmailData(e.target.value)} placeholder="Enter your email address" />
                </div>
                <div className="col-12">
                  <button type="submit" className="btn btn-dark m-0" onClick={onSubmit} disabled={isUpdate}>Subscribe</button>
                </div>
              </form> */}
            </div>
            <div className="col-md-7 col-lg-7">
              <div className="row g-4 g-lg-5 justify-content-end">
                <div className="col-6 col-sm-4">
                  {/* <ul className="nav flex-column">
                    <li className="nav-item"><a className="nav-link pt-0" href={siteData.facebook_link} target="_blank"><i className="fab fa-facebook-square text-facebook me-2"></i>Facebook</a></li>
                    <li className="nav-item"><a className="nav-link" href={siteData.linkedin_link} target="_blank"><i className="fab fa-linkedin text-linkedin me-2"></i>Linkedin</a></li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-12">
              <div className="d-md-flex justify-content-between align-items-center pt-2 pb-4 text-center">
                <div className="text-primary-hover">{siteData.copyright}</div>
                <div className="nav justify-content-center mt-3 mt-md-0">
                  <ul className="list-inline mb-0">
                    {/* <li className="list-inline-item"><Link to={"/terms"} className="nav-link">{staticData.siteData.terms[activeLang]}</Link></li> */}
                    <li className="list-inline-item"><Link to={"/privacy"} className="nav-link">{staticData.siteData.privecy[activeLang]}</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FrontFooter;