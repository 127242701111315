import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
// import Image from '../../elements/Image'

const UserTable = ({ idCode, email, name, lastName, phone, image, alt = "", userId, setModalType, setEditUserId, setModalClose }) => {
  const activeLang = useSelector((state) => state.lang.activeLang)
  const staticData = useSelector((state) => state.langActionStaticData.staticSiteData)
  return (
    <>
      <div className="userlist-item">
        <div className="userlist-item-info">
          {(name || lastName) &&
          <div className="userlist-name">{name + " " + lastName}</div>
          }
          <div className="userlist-meta">
            {email &&
            <div className="userlist-meta-item">
              <div className="userlist-meta-label">E-Mail</div>
              <div className="userlist-meta-data">: {email}</div>
            </div>
            }
            {phone &&
            <div className="userlist-meta-item">
              <div className="userlist-meta-label">Telefon</div>
              <div className="userlist-meta-data">: {phone}</div>
            </div>
            }
            {idCode && 
            <div className="userlist-meta-item">
              <div className="userlist-meta-label">Isikukood</div>
              <div className="userlist-meta-data">: {idCode}</div>
            </div>
            }
          </div>
        </div>
        <div className="userlist-item-options">
          {/* <button 
          type="button" 
          className="btn-edit" 
          data-bs-toggle="modal" 
          data-bs-target="#staticBackdrop"  
          onClick={() => {
            setModalType("edit")
            setEditUserId(userId)
            setModalClose(true)
          }}>
            <i className="fas fa-fw fa-pen"></i>
            <span>{staticData.userListTable.editUserBtn[activeLang]}</span>
          </button> */}
          <Link to={`/user/${userId}`} className="btn-primary-soft btn-action">
            <i className="fas fa-fw fa-file-alt"></i>
            <span>{staticData.userListTable.viewCourseBtn[activeLang]}</span>
          </Link>
        </div>
      </div>
    </>

  )
}

export default UserTable