const DashboardTopSec = ({ name, lastName = '' }) => {
  return (
    <section className='pt-0 bg-light'>
      <div className='container-fluid px-0'>
        <div
          className='card bg-blue h-20px h-md-20px rounded-0'
          style={{
            backgroundImage: `url(assets/images/pattern/04.png)`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
          }}
        ></div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='card bg-transparent card-body pb-0 px-0 mt-2 mt-sm-0'>
              <div className='row d-sm-flex justify-sm-content-between mt-2 mt-md-0'>
                <div className='col d-sm-flex justify-content-between align-items-center'>
                  <div className="w-75">
                    {/* <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-15 w-75 rounded'>
                    </div> */}
                    <h1 className='my-1 fs-4'>
                      {name}&nbsp;{lastName}
                    </h1>
                  </div>
                </div>
              </div>
            </div>

            <hr className='d-xl-none' />
            <div className='col-12 col-xl-3 d-flex justify-content-between align-items-center'>
              <a className='h6 mb-0 fw-bold d-xl-none' href='#!'>
                Menu
              </a>
              <button
                className='btn btn-primary d-xl-none'
                type='button'
                data-bs-toggle='offcanvas'
                data-bs-target='#offcanvasSidebar'
                aria-controls='offcanvasSidebar'
              >
                <i className='fas fa-sliders-h'></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashboardTopSec;
