import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Image from "../../elements/Image";

const CourseVideoPlayerImage = () => {
  const auth = useSelector((state) => state.auth.user)
  const activeLang = useSelector(state => state.lang.activeLang)
  const courseDetails = useSelector(state => state.course.details.data)

  return (
    <div className='col-lg-4 pt-5 pt-lg-0 offset-lg-1'>
      <div className='card shadow p-2 mb-4 z-index-9'>
        <div className='overflow-hidden rounded-3'>
          <Image
            src={courseDetails.image}
            classes='card-img'
            alt={courseDetails.title && (courseDetails.title[activeLang] || '')}
          />

          {
            courseDetails?.video &&
            <>
              <div className='bg-overlay bg-dark opacity-6'></div>
              <div className='card-img-overlay d-flex align-items-start flex-column p-3'>
                <div className='m-auto'>
                  <Link
                    to={`${auth?._id ? "/course-video-player"+courseDetails._id : "/sign-in"}`}
                    className='btn btn-lg text-danger btn-round btn-white-shadow mb-0'
                  >
                    <i className='fas fa-play'></i>
                  </Link>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default CourseVideoPlayerImage;
