import React from 'react';
import Image from '../elements/Image';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

const Testimonial = () => {
  const testimonialData = useSelector(state => state.testimonial.testimonialData)
  const activeLang = useSelector((state) => state.lang.activeLang)

  return (
    <>
      <section className="bg-light position-relative">
        {/* <figure className="position-absolute start-0 bottom-0 mb-0">
          <Image src={'assets/images/element/10.svg'} alt={''} width={'100%'} height={'100%'} effect={'blur'} classes={'h-200px'}/>
        </figure> */}

        <div className="container">
          <div className="row">
            <Swiper
              spaceBetween={30}
              slidesPerView={1}
              loop={true}
              modules={[Navigation]}
              navigation={true}
              className={'testimonial-slider'}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                600: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
            >
              {
                testimonialData?.map((list) =>
                  <SwiperSlide key={list._id}>
                    <div className="bg-body text-center p-4 rounded-3 border border-1 position-relative">
                      <div className="avatar avatar-lg mb-3">
                        <Image src={list.image} alt={""} classes={"avatar-img rounded-circle"} />
                      </div>
                      <h6 className="mb-2">{list.name? (list.name[activeLang] || '') : ''}</h6>
                      <blockquote className="mt-1">
                        <div>
                          <span className="me-1 small"><i className="fas fa-quote-left"></i></span>
                          <span className='testimonial_description' dangerouslySetInnerHTML={{__html: list.description ? (list.description[activeLang] || '') : ''}}>
                          </span>
                          <span className="ms-1 small"><i className="fas fa-quote-right"></i></span>
                        </div>
                      </blockquote>
                    </div>
                  </SwiperSlide>
                )
              }
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};



export default Testimonial;