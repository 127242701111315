import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    staticSiteData: {
        siteData: {
            home: {
                e: "home",
                en: "Kursused"
            },
            contactUs: {
                e: "Contact Us",
                en: "Kontakt"
            },
            privecy: {
                e: "Privacy Policy",
                en: "Privaatsuspoliitika"
            },
            terms: {
                e: "Terms of Use",
                en: "Kasutustingimused"
            },
        },
        login: {
            e: "Login",
            en: "Logi sisse"
        },
        myCourseList: {
            e: "My Courses List",
            en: "Kursused",
        },
        bannerButton: [
            {
                buttonOne: {
                    e: "How To Order ?",
                    en: "Kuidas tellida?",
                }
            },
            {
                buttonTwo: {
                    e: "Sign In To Start",
                    en: "Alustamiseks logi sisse",
                }
            }
        ],
        courseHeading: {
            e: "Courses",
            en: "Kursused"
        },
        getInTouchBtn: {
            e: "Get In Touch",
            en: "Võtke ühendust"
        },
        contactBtn: {
            e: "Contact",
            en: "Võtke ühendust"
        },
        couseOverViewTabs: [
            {
                overview: {
                    e: "overview",
                    en: "Ülevaade"
                },
                curriculam: {
                    e: "curriculam",
                    en: "Õppekava"
                },
                instructor: {
                    e: "instructor",
                    en: "Koolitaja"
                },
                faq: {
                    e: "FAQ-s",
                    en: "KKK-d"
                },
            }
        ],
        courseDesc: {
            e: "Course Description",
            en: "Kursuse ülevaade"
        },
        aboutInstractor: {
            e: "About instructor",
            en: "Koolitajast lähemalt"
        },
        webEmail: {
            web: {
                e: "Web",
                en: "Koduleht"
            },
            email: {
                e: "Mail ID",
                en: "E-mail"
            }
        },
        dashboardBtn: {
            certificateBtn: {
                e: "certificate",
                en: "Sertifikaat"
            },
            completeBtn: {
                e: "complete",
                en: "Läbitud"
            },
            continueBtn: {
                e: "continue",
                en: "Alusta"
            },
        },
        userDropDownBtn: {
            myCourse: {
                e: "My Course",
                en: "Kursused"
            },
            myUser: {
                e: "My Users",
                en: "Kasutajad"
            },
            myCourse: {
                e: "My Course",
                en: "Kursused"
            },
            profile: {
                e: "Profile",
                en: "Profiil"
            },
            signOut: {
                e: "Sign Out",
                en: "Logi välja"
            },
        },
        courseList: {
            e: "Course List",
            en: "Kursused",
        },
        courseListTab: {
            courseTitle: {
                e: "Course Title",
                en: "Kursus",
            },
            totalLacture: {
                e: "Total Lectures",
                en: "Loenguid kokku",
            },
            completeLacture: {
                e: "Completed Lecture",
                en: "Lõpetatud loenguid",
            },
            action: {
                e: "Action",
                en: "Toiming",
            },
        },
        editProfile: {
            heading: {
                e: "Edit Profile",
                en: "Profiil"
            },
            IDCode: {
                e: "ID Code",
                en: "Isikukood"
            },
            FirstName: {
                e: "First name",
                en: "Eesnimi"
            },
            LastName: {
                e: "Last name",
                en: "Perekonnanimi"
            },
            emailId: {
                e: "Email id",
                en: "E-mail"
            },
            saveBtn: {
                e: "Save Changes",
                en: "Salvesta"
            }
        },
        userListTable: {
            heading: {
                e: "My Users List",
                en: "Minu kasutajate loend",
            },
            addUserBtn: {
                e: "Add User",
                en: "Lisa kasutaja",
            },
            editUserBtn: {
                e: "Edit User",
                en: "Redigeeri kasutajat",
            },
            viewCourseBtn: {
                e: "View Course",
                en: "Vaata kursust",
            },
            idCode: {
                e: "ID Code",
                en: "Isikukood",
            },
            fullName: {
                e: "Full Name",
                en: "täisnimi",
            },
            email: {
                e: "E-mail",
                en: "E-mail",
            },
            phone: {
                e: "Phone",
                en: "telefon",
            },
            action: {
                e: "Action",
                en: "Toiming",
            },
        },
        timeLeft: {
            e: "Time Left",
            en: "Aega jäänud",
        },
        nextQsBtn: {
            e: "Next Question",
            en: "Järgmine küsimus",
        },
        attemptQuizBtn: {
            e: "Attempt Quiz",
            en: "Soorita eksam",
        },
        timeOver: {
            e: "Time over",
            en: "Aeg läbi"
        },
        timeOverRetry: {
            e: "Time is over, Retry!",
            en: "Soorita uuesti"
        },
        contactPage: {
            banner: {
                heading: {
                    e: "Contact us",
                    en: "Kontakt",
                },
                subHeading: {
                    e: "We're here to help!",
                    en: "Võtke meie meeskonnaga ühendust!",
                },
            },
            title: {
                e: "Let's talk",
                en: "Kontaktivorm",
            },
            subTitle: {
                e: "To request a quote or want to meet up for coffee, contact us directly or fill out the form and we will get back to you promptly",
                en: "Kui soovite osaleda koolitusel, soovite lisainfot või abi, siis palun täitke allolev vorm ning võtame Teiega peagi ühendust!",
            },
            formData: {
                name: {
                    e: "Your Name",
                    en: "Teie nimi",
                },
                email: {
                    e: "Email",
                    en: "E-maili aadress",
                },
                companyName: {
                    e: "Company name",
                    en: "Ettevõtte nimi",
                },
                course: {
                    e: "Which training would you like to order?",
                    en: "Millist koolitust soovid tellida?",
                },
                participantsCount: {
                    e: "Participants count",
                    en: "Osalejate arv",
                },
                message: {
                    e: "Message",
                    en: "Sõnum",
                },
                sndBtn: {
                    e: "Send Message",
                    en: "Jätan andmed",
                },
            }
        },
        reSendMail: {
            e: "Resend Mail",
            en: "Saada uuesti"
        },
        backBtn: {
            e: "Back",
            en: "tagasi",
        }
    }
}


const langActionSlice = createSlice({
    name: "language actions",
    initialState,
})


export default langActionSlice.reducer