import React from 'react'
import Image from "../../elements/Image";


const LeftBanner = ({image}) => {
    return (
        <div className="col-lg-6 position-relative order-2">
            <div className="image-part">
                <Image src={image} alt={""} width={"100%"} height={"100%"} effect={"blur"} classes={"position-relative"} />
            </div>
        </div>
    )
}

export default LeftBanner