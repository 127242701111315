import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const AddUserBtn = ({ setModalType, setModalClose }) => {
    const navigate = useNavigate()
    const activeLang = useSelector((state) => state.lang.activeLang)
    const staticData = useSelector((state) => state.langActionStaticData.staticSiteData)

    return (
        <button
            type="button"
            className="btn-primary add-user-btn"
            // data-bs-toggle="modal"
            // data-bs-target="#staticBackdrop"
            onClick={() => {
                navigate(window.location.pathname === '/my-course' ? '/manage-users' : '/my-course')
                // setModalType("add")
                // setModalClose(true)
            }}
        >
            {/* <i className="fas fa-fw fa-plus me-1"></i> */}
            <span>{ window.location.pathname === '/manage-users' ? 'Kasutajate loend' : staticData.userListTable.addUserBtn[activeLang]}</span>
        </button>
    )
}

export default AddUserBtn