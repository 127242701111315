import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pageData: {},
    callToActionData: {},
}

const pageSlice = createSlice({
    name: "testimonial",
    initialState,
    reducers: {
        pageSecData: (state, action) => {
            state.pageData = action.payload
        },
        ctaSecData: (state, action) => {
            state.callToActionData = action.payload
        }
    }
})

export const { pageSecData, ctaSecData } = pageSlice.actions
export default pageSlice.reducer