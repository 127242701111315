import React from 'react'
import NoResult from '../../assets/images/no-result.svg'

const NoCourseAvailable = () => {
    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='no-found-image'>
                <img src={NoResult} alt="no result" />
                <h3 className='text-center'>Vabandust! Tulemust ei leitud.</h3>
            </div>
        </div>
    )
}

export default NoCourseAvailable