import React from 'react'
import CourseTableLoaderItem from "./CourseTableLoaderItem"

const CourseTableLoader = () => {
    return (
      <div className="courseslist">
        <CourseTableLoaderItem/>
        <CourseTableLoaderItem/>
        <CourseTableLoaderItem/>
        <CourseTableLoaderItem/>
        <CourseTableLoaderItem/>
      </div>
    )
}

export default CourseTableLoader