import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import EditProfileForm from '../components/pagesections/EditProfileForm'
import EditProfileSideMenu from '../components/pagesections/EditProfileSideMenu'
import EditProfileTop from '../components/pagesections/EditProfileTop'
import { useSelector } from 'react-redux'
import MetaTitle from '../components/documenuts/MetaTitle'
import { authLogout } from '../store/authSlice'

const EditProfile = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const user = useSelector((state) => state.auth?.user);
    const activeLang = useSelector((state) => state.lang.activeLang)
    const staticData = useSelector((state) => state.langActionStaticData.staticSiteData)

    const logout = () => {
        dispatch(authLogout())
        localStorage.removeItem('accessToken');
        localStorage.removeItem('activeToken');
        navigate('/')
    }


    const profileSideMenu = [
        {
            _id: 1,
            name: `${(user?.role_details.code === "company" && !user?.is_company_owner) ? staticData.userDropDownBtn.myUser[activeLang] : staticData.userDropDownBtn.myCourse[activeLang]}`,
            link: '/my-course',
            icon: 'bi bi-ui-checks-grid',
            classes: '',
            type: 'link',
        },
        {
            _id: 2,
            name: `${staticData.userDropDownBtn.profile[activeLang]}`,
            link: '/edit-profile',
            icon: 'bi bi-person',
            classes: '',
            type: 'link',
        },
        {
            _id: 3,
            name: `${staticData.userDropDownBtn.signOut[activeLang]}`,
            link: '/',
            onClick: (e) => {
                e.preventDefault()
                logout()
            },
            icon: 'bi bi-power',
            classes: 'bg-danger-soft-hover',
            type: 'button',
        },
    ]
    return (
        <>

            <EditProfileTop />
            <section className="pt-0 bg-light">
                <div className="container">
                    <div className="row">
                        <EditProfileSideMenu menu={profileSideMenu} />
                        <EditProfileForm />
                    </div>
                </div>
            </section>
        </>
    )
}

export default EditProfile