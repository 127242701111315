import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    testimonialData: []
}

const testimonialsSlice = createSlice({
    name:"testimonial",
    initialState,
    reducers:{
        testimonialsData: (state,action)=>{
            state.testimonialData = action.payload
        }
    }
})

export const {testimonialsData} = testimonialsSlice.actions
export default testimonialsSlice.reducer