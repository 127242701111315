import moment from "moment/moment";

const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

export const formatDateTZ = (date, formatString = '', timeZone = browserTimezone) => {
  if (!date) return ''

  const convertedDate = new Date(
    date
  ).toLocaleString('en-US', { timeZone })

  return moment(new Date(convertedDate)).format(formatString)
}

export const toHHMM = (seconds, showSeconds = false) => {
  let hours = Math.floor(seconds / 3600) || 0;

  if (`${hours}`.length < 2) {
    hours = `0${hours}`;
  }

  let formattedString =
    hours + ":" + ("0" + (Math.floor(seconds / 60) % 60 || 0)).slice(-2);

  if (showSeconds) {
    formattedString += ":" + ("0" + (seconds % 60 || 0)).slice(-2);
  }

  return formattedString;
};

export const toHHMMSS = (seconds, showSeconds = false) => {
  let hours = Math.floor(seconds / 3600) || 0;
  let minutes = ("0" + (Math.floor(seconds / 60) % 60 || 0)).slice(-2)
  let totalSeconds = ("0" + (seconds % 60 || 0)).slice(-2)

  if (`${hours}`.length < 2) {
    hours = `0${hours}`;
  }

  return (
    (hours ? `${hours}h ` : '') +
    (`${minutes}m `) + 
    (showSeconds ? `${totalSeconds}s` : '')
  )
};

export const toMMSS = (seconds, showSeconds = true) => {
  let formattedString =
    ("0" + (Math.floor(seconds / 60) % 60 || 0)).slice(-2);

  if (showSeconds) {
    formattedString += ":" + ("0" + (seconds % 60 || 0)).slice(-2);
  }

  return formattedString;
};