import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { postData } from '../services/api';
import toastr from "toastr";
import Image from '../components/elements/Image';
import { useSelector } from 'react-redux';
import { toMMSS } from '../helpers/date';

const SignIn = () => {
  const siteData = useSelector(state => state.siteSettings.data)

  const [seconds, setSeconds] = useState(0);
  const activeLang = useSelector((state) => state.lang.activeLang)
  const staticData = useSelector((state) => state.langActionStaticData.staticSiteData)

  const [email, setEmail] = useState('');
  const [, setIsLogin] = useState('');
  const [isStep, setIsStep] = useState(1);
  const [message, setMessage] = useState('')
  const [intervalId, setIntervalId] = useState(null)

  const onVerify = async (e) => {
    e.preventDefault();
    setIsLogin(true);
    try {
      const userData = await postData("user-login", {
        email: email,
      });
      if (userData.status && userData.status === 200) {
        setIsLogin(false);
        setIsStep(2);
        setMessage(userData.message);

        // dispatch(authLogin(userData.data));
        // localStorage.setItem("accessToken", userData?.accessToken);
        // localStorage.setItem("role", userData?.data?.code);
        // toastr.success(userData.message);
        // return navigate("/dashboard");
      } else {
        toastr.error(userData.message);
        setIsLogin(false);
        setIsStep(1);
        setMessage('')
      }
    } catch (error) {
      toastr.error(error.message);
      setIsLogin(false);
      setIsStep(1);
      setMessage('')
    }
  };

  const handleSubmit = () => {
    onVerify();
  }

  // onKey press===========>

  const handleKeypress = e => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [intervalId])


  const onResendMailHandler = () => {
    if (intervalId) {
      clearInterval(intervalId)
    }
    setSeconds(300)
    const interval = setInterval(() => {
      setSeconds(second => {
        if (second) {
          return second - 1
        } else {
          return 0
        }
      })
    }, 1000)


    if (interval) {
      setIntervalId(interval)
    }
  }






  return (
    <>
      <section className="p-0 d-flex align-items-center position-relative overflow-hidden">
        <div className="container-fluid">
          <div className="row">
            <div className="login-background-sec col-12 col-lg-6 d-md-flex align-items-center justify-content-center bg-primary bg-opacity-10 vh-lg-100">
              <div className='login-background-image'>
                {/* <img src="assets/images/element/login-banner.png" className="img-fluid" alt="" /> */}
              </div>
              {/* <div className='opacity'></div> */}
            </div>
            <div className="col-12 col-lg-6 m-auto">
              <div className="row my-5">
                {
                  (isStep && isStep === 1) ?
                    <div className="col-sm-10 col-xl-8 m-auto">
                      <span className="mb-0 fs-1">
                        <Link to="/">
                          <div className='site-icon-logo'>
                            <Image
                              src={siteData.logo}
                              alt={"site-icon-logo"}
                              width={"100%"}
                              height={"100%"}
                              className={""}
                            />
                          </div>
                        </Link>
                      </span>
                      <h1 className="fs-2 mt-5">Logi sisse</h1>
                      <p className="lead mb-4">Tere tulemast Radisol kiirguskoolitus keskkonda!<br /> Sisse logimiseks sisesta oma e-maili aadress</p>
                      <form onSubmit={onVerify}>
                        <div className="mb-4">
                          {/* <label for="exampleInputEmail1" className="form-label">Email address *</label> */}
                          <div className="input-group input-group-lg">
                            <span className="input-group-text bg-light rounded-start border-0 text-secondary px-3"><i className="bi bi-envelope-fill"></i></span>
                            <input
                              type="email"
                              className="form-control border-0 bg-light rounded-end ps-1"
                              placeholder="E-mail"
                              id="exampleInputEmail1"
                              autoComplete='off'
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              onKeyDown={handleKeypress}
                            />
                          </div>
                        </div>

                        <div className="align-items-center mt-0">
                          <div className="d-grid">
                            <button
                              className="btn btn-primary mb-0"
                              type="button"
                              onClick={onVerify}
                            >
                              Logi sisse</button>
                          </div>
                        </div>
                      </form>
                      {/* <div className="mt-4 text-center">
                        <span>Don't have an account? <Link to='/sign-up'>Signup here</Link></span>
                      </div> */}
                    </div> :
                    <div className="col-sm-10 col-xl-8 m-auto">
                      <span className="mb-0 fs-1">
                        <Link to="/">
                          <div className='site-icon-logo'>
                            <Image
                              src={siteData.logo}
                              alt={"site-icon-logo"}
                              width={"100%"}
                              height={"100%"}
                              className={""}
                            />
                          </div>
                        </Link>
                      </span>
                      <p className="lead mb-4">{message}</p>
                      {
                        seconds > 0
                          ?
                          <>
                            <button className='resend-mail-btn me-2'><span className='me-1'><i class="fas fa-envelope"></i></span>{staticData.reSendMail[activeLang]}</button><span className='timer'>{toMMSS(seconds)}</span>
                          </>
                          :
                          <button className='resend-mail-btn active' onClick={onResendMailHandler}><span className='me-1'><i class="fas fa-envelope"></i></span>{staticData.reSendMail[activeLang]}</button>
                      }
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignIn;