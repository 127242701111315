export const getPrevVideo = (videoItem = {}, courseDetails = {}) => {
  if (!videoItem.quizzes.length) {
    return {
      playable: true,
      quizable: false
    }
  }

  const videos = []

  courseDetails?.curriculums?.forEach(curriculum => {
    curriculum.videos?.forEach(videoData => {
      videos.push(videoData)
    })
  })

  const curVidIndex = videos.findIndex(video => video._id === videoItem._id)

  if (curVidIndex === 0) {
    return {
      playable: true,
      quizable: videoItem.quizzes.length > 0 && !videoItem.passed
    }
  }

  const quizzableVideos = videos.filter(video => video.quizzes.length > 0)
  const videoIndex = quizzableVideos.findIndex(item => item._id === videoItem._id)

  if (videoIndex < 0) return false

  const prevVideo = quizzableVideos[videoIndex - 1]

  let quizable = false
  let playable = false

  if (prevVideo && prevVideo.passed) {
    playable = true
    quizable = !videoItem.passed ? true : false
  }

  return {
    playable,
    quizable
  }
}