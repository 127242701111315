import React from 'react'

const CoursePartLoader = () => {
    return (
        <div>
            <div className='text-loader fadeoutAnimate bg-light loader-h-25 w-50 mb-2 rounded'></div>

            <div className='d-flex justify-content-between mt-3'>

                <div className='box w-25'>
                    <div className='text-loader fadeoutAnimate bg-light loader-h-75 loader-w-75 mb-2 rounded-circle'></div>

                    <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-75 mb-2 rounded'></div>
                    <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-75 mb-2 rounded'></div>
                    <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-50 mb-2 rounded'></div>
                </div>
                <div className='box w-25'>
                    <div className='text-loader fadeoutAnimate bg-light loader-h-75 loader-w-75 mb-2 rounded-circle'></div>

                    <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-75 mb-2 rounded'></div>
                    <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-75 mb-2 rounded'></div>
                    <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-50 mb-2 rounded'></div>
                </div>
                <div className='box w-25'>
                    <div className='text-loader fadeoutAnimate bg-light loader-h-75 loader-w-75 mb-2 rounded-circle'></div>

                    <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-75 mb-2 rounded'></div>
                    <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-75 mb-2 rounded'></div>
                    <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-50 mb-2 rounded'></div>
                </div>
            </div>
        </div>
    )
}

export default CoursePartLoader