// import { Link } from 'react-router-dom';
// import { useSelector } from 'react-redux';

const UserTableMod = ({
  idCode,
  email,
  name,
  lastName,
  index,
  id,
  onUserDataChange = () => {}
  // phone,
  // image,
  // alt = '',
  // userId,
  // setModalType,
  // setEditUserId,
  // setModalClose,
}) => {
  return (
    <div className={'px-3 py-3 border-bottom ' + ((index%2 !== 0)?"bg-light bg-opacity-50":"")}>
      <div className='row mx-n1'>
        <div className='col-md-3 px-1'>
          <label className='form-label m-0 small'>Eesnimi</label>
          <input className="form-control" type='text' placeholder='Eesnimi' value={name} disabled={id ? true : false}
            onChange={(e) => onUserDataChange('name', e.target.value, index)}
          />
        </div>
        <div className='col-md-3 px-1'>
          <label className='form-label m-0 small'>Perekonnanimi</label>
          <input className="form-control" type='text' placeholder='Perekonnanimi' value={lastName}  disabled={id ? true : false}
            onChange={(e) => onUserDataChange('last_name', e.target.value, index)}
          />
        </div>
        <div className='col-md-3 px-1'>
          <label className='form-label m-0 small'>E-mail</label>
          <input className="form-control" type='email' placeholder='E-mail' value={email} 
            disabled={id ? true : false}
            onChange={(e) => onUserDataChange('email', e.target.value, index)}
          />
        </div>
        {/* <div className='col-3'>
          <label className='form-label m-0 small'>Phone</label>
          <input className="form-control" type='text' placeholder='Phone' />
        </div> */}
        <div className='col-md-3 px-1'>
          <label className='form-label m-0 small'>Isikukood</label>
          <input className="form-control" type='number' placeholder='Isikukood' value={idCode} disabled={id ? true : false}
            onKeyDown={(e) => { if (e.which === 189) { e.preventDefault() } }}
            onChange={(e) => {
              if (e.target.value >= 0) {
                onUserDataChange('id_code', e.target.value, index)
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

// <div className="userlist-item">
//   <div className="userlist-item-info">
//     <label>Name</label>
//     <input className="form-control" type="text" value={name + " " + lastName} />
//     {/* {(name || lastName) &&
//     <div className="userlist-name">{name + " " + lastName}</div>
//     } */}
//     <div className="userlist-meta">
//       {/* {email &&
//       <div className="userlist-meta-item">
//         <div className="userlist-meta-label">E-Mail</div>
//         <div className="userlist-meta-data">: {email}</div>
//       </div>
//       } */}
//       <label>E-Mail</label>
//       <input className="form-control" type="text" value={email} />
//       {/* {phone &&
//       <div className="userlist-meta-item">
//         <div className="userlist-meta-label">Telefon</div>
//         <div className="userlist-meta-data">: {phone}</div>
//       </div>
//       } */}
//       <label>Telefon</label>
//       <input className="form-control" type="text" value={phone} />
//       {/* {idCode &&
//       <div className="userlist-meta-item">
//         <div className="userlist-meta-label">Isikukood</div>
//         <div className="userlist-meta-data">: {idCode}</div>
//       </div>
//       } */}
//       <label>Isikukood</label>
//       <input className="form-control" type="text" value={idCode} />
//     </div>
//   </div>
//   <div className="userlist-item-options">
//     <button
//     type="button"
//     className="btn-edit"
//     data-bs-toggle="modal"
//     data-bs-target="#staticBackdrop"
//     onClick={() => {
//       setModalType("edit")
//       setEditUserId(userId)
//       setModalClose(true)
//     }}>
//       <i className="fas fa-fw fa-pen"></i>
//       <span>{staticData.userListTable.editUserBtn[activeLang]}</span>
//     </button>
//     <Link to={`/user/${userId}`} className="btn-primary-soft btn-action">
//       <i className="fas fa-fw fa-file-alt"></i>
//       <span>{staticData.userListTable.viewCourseBtn[activeLang]}</span>
//     </Link>
//   </div>
// </div>

export default UserTableMod;
