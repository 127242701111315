import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Courses from '../components/pagesections/Courses';
import HowItWorks from '../components/pagesections/HowItWorks';
import MainBanner from '../components/pagesections/MainBanner';
import Testimonial from '../components/pagesections/Testimonial';
import { postData } from '../services/api';
import {
  getCourseListRequested,
  getCourseList,
  getCourseListFailed,
} from '../store/courseSlice';
import { testimonialsData } from '../store/testimonialsSlice';
import { ctaSecData, pageSecData } from '../store/pageSlice';
import { bannerData } from '../store/bannerDataSlice';
import CallToAction from "../components/pagesections/CallToAction";

const Home = () => {
  const dispatch = useDispatch()

  // course sec development===================>
  useEffect(() => {
    const loadCourses = async () => {
      dispatch(getCourseListRequested())
      const res = await postData('course/list', {})
      if (res.status === 200) {
        dispatch(getCourseList(res.data))
      } else {
        dispatch(getCourseListFailed())
      }
    }

    loadCourses()
  }, [dispatch])


  // testimonials sec development===============>

  useEffect(() => {
    const loadCourses = async () => {
      const res = await postData('testimonial/list', {})
      if (res.status === 200) {
        dispatch(testimonialsData(res.data))
      } else {
        console.log("can't fetch testimonial data")
      }
    }

    loadCourses()
  }, [dispatch])

  // page details or how it works sec development =============>

  useEffect(() => {
    const loadCourses = async () => {
      const res = await postData('page/details', {
        // "alias": "muuda-oma-kiirgustegevuse"
        "alias": "how-to-order"
      })
      if (res.status === 200) {
        dispatch(pageSecData({ howToOrder: res.data }))
      } else {
        console.log("can't fetch page data")
      }
    }
    loadCourses()
  }, [dispatch])

  // page details or how it works sec development =============>

  useEffect(() => {
    const loadCourses = async () => {
      const res = await postData('page/details', {
        "alias": "muuda-oma-kiirgustegevuse"
        // "alias": "how-to-order"
      })
      if (res.status === 200) {
        dispatch(ctaSecData({ howToOrder: res.data }))
      } else {
        console.log("can't fetch page data")
      }
    }
    loadCourses()
  }, [dispatch])

  // banner sec api call============>

  useEffect(() => {
    const loadCourses = async () => {
      const res = await postData('banner/list', {});
      if (res.status === 200) {
        dispatch(bannerData(res.data))
      }
    }
    loadCourses()
  }, [dispatch])

  return (
    <>

      <MainBanner />
      <Courses />
      <HowItWorks />
      {/* <Testimonial /> */}
      <CallToAction />
    </>
  );
};

export default Home;
