import { decodeHTML } from "../../../helpers";

const Overview = ({ description }) => {

  return (
    <div
      className='tab-pane fade show active'
      id='course-pills-1'
      role='tabpanel'
      aria-labelledby='course-pills-tab-1'
    >
      {/* <h5 className='mb-3'>Course Description</h5> */}
      <div dangerouslySetInnerHTML={{ __html: decodeHTML(description) }}></div>
    </div>
  );
};

export default Overview;
