import { useSelector } from 'react-redux';
import Overview from './CourseDetailsSection/Overview';
import CourseCurriculum from './CourseDetailsSection/CourseCurriculum';
import CourseInstructor from './CourseDetailsSection/CourseInstructor';
import CourseFAQ from './CourseDetailsSection/CourseFAQ';
import TermsLoader from '../Loader/TermsLoader';

const CourseOverview = () => {
  const courseDetails = useSelector(state => state.course.details.data)
  const activeLang = useSelector((state) => state.lang.activeLang)
  const staticData = useSelector((state) => state.langActionStaticData.staticSiteData)
  const loading = useSelector(state => state.course.details.loading)

  return (
    <section className='pb-0 py-lg-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='card shadow rounded-2 p-0 mb-4'>
              <div className='card-header border-bottom px-2 px-lg-4 py-3'>
                <ul
                  className='nav nav-pills nav-tabs-line py-2 flex-nowrap overflow-auto'
                  id='course-pills-tab'
                  role='tablist'
                >
                  <li className='nav-item me-sm-4' role='presentation'>
                    <button
                      className='nav-link mb-0 text-nowrap active'
                      id='course-pills-tab-1'
                      data-bs-toggle='pill'
                      data-bs-target='#course-pills-1'
                      type='button'
                      role='tab'
                      aria-controls='course-pills-1'
                      aria-selected='true'
                    >
                      {staticData.couseOverViewTabs[0].overview[activeLang]}
                    </button>
                  </li>
                  {
                    courseDetails?.show_curriculum &&
                    <li className='nav-item me-sm-4' role='presentation'>
                      <button
                        className='nav-link mb-0 text-nowrap'
                        id='course-pills-tab-2'
                        data-bs-toggle='pill'
                        data-bs-target='#course-pills-2'
                        type='button'
                        role='tab'
                        aria-controls='course-pills-2'
                        aria-selected='false'
                      >
                        {staticData.couseOverViewTabs[0].curriculam[activeLang]}
                      </button>
                    </li>
                  }
                  {/* <li className='nav-item me-sm-4' role='presentation'>
                    <button
                      className='nav-link mb-0 text-nowrap'
                      id='course-pills-tab-3'
                      data-bs-toggle='pill'
                      data-bs-target='#course-pills-3'
                      type='button'
                      role='tab'
                      aria-controls='course-pills-3'
                      aria-selected='false'
                    >
                      {staticData.couseOverViewTabs[0].instructor[activeLang]}
                    </button>
                  </li> */}
                  {/* <li className="nav-item me-sm-4" role="presentation">
                      <button className="nav-link mb-0 text-nowrap" id="course-pills-tab-4" data-bs-toggle="pill" data-bs-target="#course-pills-4" type="button" role="tab" aria-controls="course-pills-4" aria-selected="false">Reviews</button>
                    </li> */}
                  {
                    courseDetails?.show_faq &&
                    <li className='nav-item me-sm-4' role='presentation'>
                      <button
                        className='nav-link mb-0 text-nowrap'
                        id='course-pills-tab-5'
                        data-bs-toggle='pill'
                        data-bs-target='#course-pills-5'
                        type='button'
                        role='tab'
                        aria-controls='course-pills-5'
                        aria-selected='false'
                      >
                        {staticData.couseOverViewTabs[0].faq[activeLang]}{' '}
                      </button>
                    </li>
                  }
                  {/* <li className="nav-item me-sm-4" role="presentation">
                      <button className="nav-link mb-0 text-nowrap" id="course-pills-tab-6" data-bs-toggle="pill" data-bs-target="#course-pills-6" type="button" role="tab" aria-controls="course-pills-6" aria-selected="false">Comment</button>
                    </li> */}
                </ul>
              </div>
              <div className='card-body p-4'>
                {
                  loading === true
                    ?
                    <TermsLoader />
                    :
                    <div className='tab-content pt-2' id='course-pills-tabContent'>
                      <Overview
                        description={courseDetails.description ? (courseDetails.description[activeLang] || '') : ''}
                      />
                      {
                        courseDetails?.show_curriculum &&
                        <CourseCurriculum curriculums={courseDetails.curriculums} />
                      }
                      {/* <CourseInstructor instructor={courseDetails.instructor} /> */}
                      {
                        courseDetails?.show_faq &&
                        <CourseFAQ faqs={courseDetails.faqs} />
                      }
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CourseOverview;
