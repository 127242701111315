import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router';
import MetaTitle from '../components/documenuts/MetaTitle';

const FreeAuthRoute = ({ auth, children,title = "" }) => {
  if (localStorage.getItem('accessToken')) {
    return <Navigate to="/my-course" />
  }
  return (
    <>
      <MetaTitle title={`Radisol | ${title}`} />
      {children}
    </>
  )
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}
export default connect(mapStateToProps, null)(FreeAuthRoute);