import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    myCourseList:[],
    loading: false
}

const myCourseSlice = createSlice({
    name:"my-course-list",
    initialState,
    reducers:{
        myCourseListData: (state,action)=>{
            state.myCourseList = action.payload
        },
        isLoading: (state,action)=>{
            state.loading = action.payload
        },
    }
})

export const {myCourseListData, isLoading} = myCourseSlice.actions
export default myCourseSlice.reducer