import React from 'react';
import { useSelector } from 'react-redux';
import TermsHeaderLoader from '../Loader/TermsHeaderLoader';

const PageBanner = ({ data }) => {
  const activeLang = useSelector((state) => state.lang.activeLang)

  return (
    <>
      <section class="bg-primary bg-opacity-10">
        <div class="container">
          {
            data[0].title === undefined ?
              <TermsHeaderLoader />
              :
              <div class="row">
                {data.map((item) =>
                (<div class="col-lg-8 mx-auto text-center" key={item._id}>
                  <h1 class="display-6">{item.title ? (item.title[activeLang] || "") : ""}</h1>
                  {/* <p class="mb-0">{item.subText ? (item.subText[activeLang] || "") : ""}</p> */}
                </div>)
                )}

              </div>
          }
        </div>
      </section>
    </>
  );
};

export default PageBanner;