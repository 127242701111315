import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import DashboardTopSec from '../components/pagesections/DashboardTopSec'
import UserDashboardBody from '../components/pagesections/UserDashboardBody'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { postData } from '../services/api';
import { isLoading, removeUserDataHandler, userDataHandler, userDetailsHandler } from '../store/myUserSlice';
import { authLogout } from '../store/authSlice';

const UserDashboard = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector((state) => state.auth?.user);
    const activeLang = useSelector((state) => state.lang.activeLang)
    const staticData = useSelector((state) => state.langActionStaticData.staticSiteData)
    const userDetails = useSelector((state) => state?.userDataList.userDetails)
    const { id } = useParams()

    const logout = () => {
        dispatch(authLogout())
        localStorage.removeItem('accessToken');
        localStorage.removeItem('activeToken');
        navigate('/')
    }

    const profileSideMenu = [
        {
            _id: 1,
            name: `${(user?.role_details.code === "company" && !user?.is_company_owner) ? staticData.userDropDownBtn.myUser[activeLang] : staticData.userDropDownBtn.myCourse[activeLang]}`,
            link: '/my-course',
            icon: 'bi bi-ui-checks-grid',
            classes: '',
            type: 'link',
        },
        {
            _id: 2,
            name: `${staticData.userDropDownBtn.profile[activeLang]}`,
            link: '/edit-profile',
            icon: 'bi bi-person',
            classes: '',
            type: 'link',
        },
        {
            _id: 3,
            name: `${staticData.userDropDownBtn.signOut[activeLang]}`,
            link: '/',
            onClick: (e) => {
                e.preventDefault()
                logout()
            },
            icon: 'bi bi-power',
            classes: 'bg-danger-soft-hover',
            type: 'button',
        },
    ]

    useEffect(() => {
        if (id) {
            const userDetails = async () => {
                const res = await postData('user/name/details', {
                    id: id,
                })
                if (res.status === 200) {
                    dispatch(userDetailsHandler(res.data))
                }
            }
            userDetails()
        }
        return () => {
            dispatch(removeUserDataHandler())
        }
    }, [id, dispatch])

    useEffect(() => {
        if (id) {
            dispatch(isLoading(true))
            const userCourseList = async () => {
                const res = await postData('course/list/me', {
                    userId: id,
                })
                if (res.status === 200) {
                    dispatch(isLoading(false))
                    dispatch(userDataHandler(res.data))
                } else {
                    dispatch(isLoading(false))
                }
            }
            userCourseList()
        }
        return () => {
            dispatch(removeUserDataHandler())
        }
    }, [id, dispatch])


    return (
        <>
            <DashboardTopSec name={userDetails?.name} lastName={userDetails?.last_name} />
            <UserDashboardBody profileSideMenu={profileSideMenu} />
        </>
    )
}

export default UserDashboard