import { Link } from 'react-router-dom'

const FloatingBtn = ({ courseDetails, video }) => {


    const getVideoId = () => {
        let videoId
        courseDetails?.curriculums?.forEach((item) => {
            item.videos.forEach((videoItem) => {
                if (decodeURIComponent(videoItem.video) === decodeURIComponent(video)) {
                    videoId = videoItem._id
                }
            })
        })
        return videoId
    }

    if (!getVideoId()) {
        return <></>
    }

    return (
        <div className='floating-quiz p-3'>
            <button type='button' className='border-0'>
                <Link
                    to={`/course-details-quiz/${courseDetails?._id}/${getVideoId()}`}
                    className='btn btn-sm btn-success mb-0 btn-attempt'
                >
                    Soorita Eksam
                </Link>
            </button>
        </div>
    )
}

export default FloatingBtn