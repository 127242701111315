import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Contact = () => {
  const activeLang = useSelector((state) => state.lang.activeLang)
  const staticData = useSelector((state)=>state.langActionStaticData.staticSiteData)
  return (
    <div className='flex'>
      <Link to='/sign-in'>
        <button type='button' className='btn btn-primary text-white'>
          <i className='fas fa-sign-in-alt text-white me-2'></i>{staticData.login[activeLang]}{' '}
        </button>
      </Link>
      <Link to='/contact-us'>
        <button type='button' className='btn btn-primary ms-2 '>
          <i className='fas fa-envelope me-2'></i>{staticData.contactBtn[activeLang]}
        </button>
      </Link>
    </div>
  );
};

export default Contact;
