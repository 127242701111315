import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import StudentDashboardSec from '../components/pagesections/DashboardSec';
import DashboardTopSec from '../components/pagesections/DashboardTopSec';
import { useDispatch, useSelector } from 'react-redux';
import { postData } from '../services/api';
import { isLoading, myCourseListData } from '../store/myCourseSlice';
import { authLogout } from '../store/authSlice';

const Dashboard = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth?.user);
  const activeLang = useSelector((state) => state.lang.activeLang);
  const staticData = useSelector(
    (state) => state.langActionStaticData.staticSiteData
  );

  const navigate = useNavigate()

  const logout = () => {
    dispatch(authLogout());
    localStorage.removeItem('accessToken');
    localStorage.removeItem('activeToken');
    navigate('/');
  };

  const profileSideMenu = [
    {
      _id: 1,
      name: `${
        (user?.role_details?.code === 'company' && !user?.is_company_owner)
          ? staticData.userDropDownBtn.myUser[activeLang]
          : staticData.userDropDownBtn.myCourse[activeLang]
      }`,
      link: '/my-course',
      icon: 'bi bi-ui-checks-grid',
      classes: '',
      type: 'link',
    },
    {
      _id: 2,
      name: `${staticData.userDropDownBtn.profile[activeLang]}`,
      link: '/edit-profile',
      icon: 'bi bi-person',
      classes: '',
      type: 'link',
    },
    {
      _id: 3,
      name: `${staticData.userDropDownBtn.signOut[activeLang]}`,
      link: '/',
      onClick: (e) => {
        e.preventDefault()
        logout()
      },
      icon: 'bi bi-power',
      classes: 'bg-danger-soft-hover',
      type: 'button',
    },
  ];

  useEffect(() => {
    const loadCourseDetails = async () => {
      dispatch(isLoading(true));
      const res = await postData('/course/list/me', {});
      if (res.status === 200) {
        dispatch(myCourseListData(res.data));
        dispatch(isLoading(false));
      } else {
        dispatch(isLoading(false));
      }
    };

    loadCourseDetails();
  }, [dispatch]);

  return (
    <>
      <DashboardTopSec name={user?.name} />
      <StudentDashboardSec profileSideMenu={profileSideMenu} user={user} />
    </>
  );
};

export default Dashboard;
