import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userData: [],
    userDetails: {},
    loading:false
}

const myUserSlice = createSlice({
    name: "user-data-list",
    initialState,
    reducers: {
        userDataHandler: (state, action) => {
            state.userData = action.payload
        },
        userDetailsHandler: (state, action) => {
            state.userDetails = action.payload
        },
        removeUserDataHandler: (state, action) => {
            state.userData = []
            state.userDetails = {}
        },
        isLoading:(state,action)=>{
            state.loading = action.payload
        }
    }
})

export const { userDataHandler, removeUserDataHandler, userDetailsHandler,isLoading } = myUserSlice.actions
export default myUserSlice.reducer