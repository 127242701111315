import React from "react";
import { Link } from "react-router-dom";

const SectionBanner = () => {
  return (
    <>
      <section className="bg-blue align-items-center d-flex" style={{backgroundImage:`url(assets/images/pattern/04.png)` , backgroundRepaet:'no-repeat', backgroundPosition:'center center', backgroundSize:'cover',}}>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="text-white">Course List Classic</h1>
              <div className="d-flex justify-content-center">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb breadcrumb-dark breadcrumb-dots mb-0">
                    <li className="breadcrumb-item">
                      <Link to={"/"}>Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Courses classic
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionBanner;