import React from 'react';
import TermsLoader from '../Loader/TermsLoader';


const PrivacyContent = ({ data }) => {
  return (
    <>
      <section>
        <div className='container'>
          <div className='row'>
            {data.length > 0 ?
              <div className='col-md-12 mt-4' dangerouslySetInnerHTML={{ __html: data }}>

              </div> :
              <TermsLoader />
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyContent;