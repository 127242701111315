// import { useSelector } from 'react-redux';

// import Image from '../elements/Image';
// import { Link } from 'react-router-dom';
import CourseDescription from "./CourseDetailsSection/CourseDescription";
import Contact from "../items/Contact";

// import { formatDateTZ } from '../../helpers/date';
import CourseVideoPlayerImage from "./CourseDetailsSection/CourseVideoPlayerImage";
import VideoBoxLoader from "../Loader/VideoBoxLoader";
import { useSelector } from "react-redux";

const CourseBanner = () => {
  const courseDetails = useSelector((state) => state.course.details.data);
  const loading = useSelector((state) => state.course.details.loading);

  return (
    <section className="bg-light py-0 py-sm-5 course-details-banner">
      <div className="container">
        <div className="row py-5">
          <div className="col-lg-7">
            <CourseDescription />
            <div className="contact-price-sec d-flex align-items-center flex-wrap mt-4">
              <div className="course-details-contact">
                <Contact />
              </div>
              {courseDetails?.price > 0 && (
                <div className="course-details-price d-flex align-items-center ms-auto">
                  <div className="d-flex gap-1 align-items-center">
                    <span className="p-1 px-2 flex whitespace-nowrap">
                      Koolituse hind
                    </span>{" "}
                    :{" "}
                  </div>
                  <div className="d-flex gap-1">
                    <div className="fw-bold text-black d-flex align-items-center ms-1">
                      <span>€</span>
                      {courseDetails?.price && courseDetails?.discount ? (
                        <span className="">
                          {courseDetails?.price -
                            (courseDetails?.price * courseDetails?.discount) /
                            100}
                        </span>
                      ) : (
                        <span className="">
                          {courseDetails?.price}
                        </span>
                        // <div className="text-loader fadeoutAnimate loader-h-25 ms-1 rounded"></div>
                      )}
                    </div>
                    {courseDetails?.price > 0 && courseDetails?.discount > 0 && (
                      <div>
                        <s>
                          <span>€</span>
                          <span>{courseDetails?.price}</span>
                        </s>
                      </div>
                    )}
                    {courseDetails?.discount > 0 && (
                      <div>
                        <span className="badge badge-warning py-1">
                          <span>{"-"}{courseDetails?.discount}</span>
                          <span>{"%"}</span>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {loading === true ? (
            <div className="col-lg-4 pt-5 pt-lg-0 offset-lg-1">
              <div className="card shadow p-2 mb-4 z-index-9">
                <VideoBoxLoader />
              </div>
            </div>
          ) : (
            <CourseVideoPlayerImage />
          )}
        </div>
      </div>
    </section>
  );
};

export default CourseBanner;
