import React from 'react'
import HelpCard from './HelpCard'
import ContactBoxLoader from '../Loader/ContactBoxLoader'
import { useSelector } from 'react-redux'

const ContactUsHeaderSec = ({ headerSecData }) => {
    const activeLang = useSelector((state) => state.lang.activeLang)
    const staticData = useSelector((state)=>state.langActionStaticData.staticSiteData)

    return (
        <>
            <section className='contact-us-section' style={{ backgroundImage: `url(assets/images/contact-us.jpg)` }}>
                <div className='container position-relative'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='text-part'>
                                <div className='sub-text'>{staticData.contactPage.banner.heading[activeLang]}</div>
                                <h1 className='heading'>{staticData.contactPage.banner.subHeading[activeLang]}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactUsHeaderSec