import React from 'react';

const CourseTableLoaderItem = () => {
  return (
    <>
      <div className="courseslist-item">
          <div className="courseslist-info">
            <div className="courseslist-image"><div className='text-loader fadeoutAnimate loader-bg-light h-100 w-100 rounded'></div></div>
            <div className="courseslist-content">
              <div className='text-loader fadeoutAnimate loader-bg-light loader-h-25 col-9 col-sm-6 rounded'></div>
              <div className="courseslist-meta">
                <div className="courseslist-meta-item">
                  <div className="courseslist-meta-label"><div className='text-loader fadeoutAnimate loader-bg-light loader-h-15 col-12 rounded'></div></div>
                  <div className="courseslist-meta-data"><div className='text-loader fadeoutAnimate loader-bg-light loader-h-15 col-12 col-sm-8 rounded'></div></div>
                </div>
                <div className="courseslist-meta-item">
                  <div className="courseslist-meta-label"><div className='text-loader fadeoutAnimate loader-bg-light loader-h-15 col-12 rounded'></div></div>
                  <div className="courseslist-meta-data"><div className='text-loader fadeoutAnimate loader-bg-light loader-h-15 col-12 col-sm-8 rounded'></div></div>
                </div>
              </div>
              <div className="courseslist-progress">
                <div className='text-loader fadeoutAnimate loader-bg-light loader-h-15 col-12 rounded'></div>
              </div>
            </div>
          </div>
          <div className="courseslist-options">
            <div className='text-loader fadeoutAnimate loader-bg-light loader-h-35 w-100 rounded'></div>
            <div className='text-loader fadeoutAnimate loader-bg-light loader-h-35 w-100 rounded'></div>
          </div>
        </div>
    </>
  );
};

export default CourseTableLoaderItem;