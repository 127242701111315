import React from 'react'
import NoResultFound from '../../assets/images/no-result.svg'


const NoResult = () => {
  return (
    <div className='no-result-found'>
      <div className='no-result-found-image'><img src={NoResultFound} alt="no result" /></div>
      <div className='no-result-found-title'>Vabandust! Tulemust ei leitud.</div>
    </div>
  )
}

export default NoResult