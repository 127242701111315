import { useEffect } from 'react';
import CreateRoutes from './routes';
import { useDispatch } from 'react-redux';
import { postData } from './services/api';
import { siteSettingsData } from './store/siteSettingsSlice';


function App() {
  const dispatch = useDispatch()
  
  useEffect(() => {
    const loadCourses = async () => {
      const res = await postData('site-settings/details', {})
      if (res.status === 200) {
        dispatch(siteSettingsData(res.data))
      } else {
        console.log("can't fetch testimonial data")
      }
    }

    loadCourses()
  }, [dispatch])

  return (
    <CreateRoutes />
  );
}

export default App;