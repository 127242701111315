import React from 'react'
import ContactUsForm from '../components/pagesections/ContactUsForm'
import ContactUsHeaderSec from '../components/pagesections/ContactUsHeaderSec'
import { useSelector } from 'react-redux';
// import MetaTitle from '../components/documenuts/MetaTitle';

const ContactUs = () => {
  const siteData = useSelector(state => state.siteSettings.data)

    const headerSecData = [
        {
            _id: 1,
            title: siteData?.customer_support_address?.supportTitle,
            // location: siteData?.customer_support_address?.supportAddress,
            phone: siteData?.customer_support_address?.supportPhone,
            email: siteData?.customer_support_address?.supportEmail,
            backgroundColor: '',
            color:''
        },
        // {
        //     _id: 3,
        //     title: siteData?.office_address?.officeTitle,
        //     location: siteData?.office_address?.officeAddress,
        //     phone: siteData?.office_address?.officePhone,
        //     email: siteData?.office_address?.officeEmail,
        //     backgroundColor: '',
        //     color:''
        // },
        {
            _id: 2,
            title: siteData?.contact_address?.contactTitle,
            location: siteData?.contact_address?.contactAddress,
            // phone: siteData?.contact_address?.contactPhone,
            email: siteData?.contact_address?.contactEmail,
            backgroundColor: 'bg-primary',
            color:'text-white'
        }
    ]

    return (
        <>
            <ContactUsHeaderSec headerSecData={headerSecData} />
            <ContactUsForm headerSecData={headerSecData}/>
        </>
    )
}

export default ContactUs