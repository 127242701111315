export const decodeHTML = (str = '') => {
  let txt = document.createElement('textarea');
  txt.innerHTML = str;
  return txt.value;
};

export const formatHHMMSS = (seconds) => {
  let hours = Math.floor(seconds / 3600) || 0;
  let minutes = Math.floor(seconds / 60) % 60 || 0;
  let totalSeconds = (seconds % 60) || 0;

  return `${hours || '00'}:${minutes || '00'}:${totalSeconds || '00'}`;
};

export const connvertHTTP = (url = '') => {
  const hasHTTP = url.substring(0, 4) === 'http'

  if (!hasHTTP) {
    return 'https://' + url
  } 
  
  return url
}

export const verifyURL = (link = '') => /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(link)