import { useState, useEffect, useRef } from 'react';
import Image from '../components/elements/Image';
import { NavLink, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authLogout } from '../store/authSlice';

// import { postData } from '../services/api';
// import toastr from 'toastr';
// import Logo from '../assets/images/logo.png';
import { getActiveLang } from '../store/langSlice';
import useOnClickOutside from "../hooks/useOnClickOutside"
import userLogo from '../assets/images/icons8-person.svg'

const FrontHeader = ({ pageParams }) => {
  const dispatch = useDispatch();
  const langs = useSelector(state => state.lang.list.data)
  const activeLang = useSelector(state => state.lang.activeLang)
  const staticData = useSelector((state) => state.langActionStaticData.staticSiteData)

  const activeLangData = langs.find(lang => lang.code === activeLang)
  // const navigate = useNavigate();
  const siteData = useSelector(state => state.siteSettings.data)

  // const accessToken =  localStorage.getItem("accessToken");
  const user = useSelector((state) => state.auth?.user);
  const [displayName, setDisplayName] = useState('');
  const [displayEmail, setDisplayEmail] = useState('');
  const [toggle, setToggle] = useState(false)
  const clickOutsideTrigger = useRef(null)
  const [menutoggle, setMenutoggle] = useState('');

  const menu = [
    { _id: 1, name: staticData.siteData.home[activeLang], link: '/' },
    { _id: 2, name: 'Kiirgustegevuse haldus', link: 'https://www.radisol.ee' },
    { _id: 3, name: staticData.siteData.contactUs[activeLang], link: '/contact-us' },
  ];

  const logout = () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('activeToken');
    dispatch(authLogout());
    window.location.reload()
  };
  
  const userMenu = [
    {
      _id: 1,
      name: `${(user?.role_details?.code === "company") ? staticData.userDropDownBtn.myUser[activeLang] : staticData.userDropDownBtn.myCourse[activeLang]}`,
      link: '/my-course',
      icon: 'bi bi-ui-checks-grid',
      classes: '',
      type: 'link',
    },
    {
      _id: 2,
      name: `${staticData.userDropDownBtn.profile[activeLang]}`,
      link: '/edit-profile',
      icon: 'bi bi-person',
      classes: '',
      type: 'link',
    },
    {
      _id: 3,
      name: `${staticData.userDropDownBtn.signOut[activeLang]}`,
      link: '/',
      onClick: (e) => {
        e.preventDefault()
        logout()
      },
      icon: 'bi bi-power',
      classes: 'bg-danger-soft-hover',
      type: 'button',
    },
  ];


  useEffect(() => {
    setDisplayName(user?.name);
    setDisplayEmail(user?.email);
    // let file = doesFileExist(user?.image);
    // if (file.status === 200) {
    //   setDisplayImage(file?.path);
    // } else {
    //   setDisplayImage(file?.path);
    // }
  }, [user]);

  useOnClickOutside(clickOutsideTrigger, () => { setToggle(false) })

  // header logo save in local storage =========>
  if (siteData?.logo) {
    localStorage.setItem("siteLogo", new URL(siteData?.logo).href);
  }
  const siteLogo = localStorage.getItem("siteLogo");

  return (
    <>
      <header className='navbar-light navbar-sticky'>
        <nav className='navbar navbar-expand-xl'>
          <div className='container'>
            <Link to={'/'} className='navbar-brand'>
              <Image
                src={!siteData?.logo ? siteLogo : siteData?.logo}
                alt={'logo'}
                width={'100%'}
                height={'100%'}
                effect={'blur'}
                classes={'light-mode-item navbar-brand-item'}
              />
            </Link>
            {
              !pageParams === "course-details-quiz" &&
              <button
                className='navbar-toggler ms-sm-auto'
                type='button'
                onClick={() => setMenutoggle(!menutoggle)}
              >
                <span className='navbar-toggler-animation'>
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>
            }
            {
              pageParams === "course-details-quiz" ?
                ""
                :
                <div className={'navbar-collapse collapse' + (menutoggle ? " show" : "")}>
                  {menu.length > 0 && (
                    <ul className='navbar-nav navbar-nav-scroll mx-auto'>
                      {menu.map((item) => item.link.startsWith("http") ? (
                        <li className='nav-item' key={item._id}>
                          <Link to={item.link} target="_blank" className='nav-link' >
                            {item.name}
                          </Link>
                        </li>
                      ) : (
                        <li className='nav-item' key={item._id}>
                          <NavLink to={item.link} className='nav-link' onClick={() => setMenutoggle(!menutoggle)}>
                            {item.name}
                          </NavLink>
                        </li >
                      )
                      )}
                    </ul>
                  )}
                </div>
            }
            {langs.length > 0 && (
              <ul className='navbar-nav navbar-nav-scroll me-3 d-none d-xl-block'>
                <li className='nav-item dropdown'>
                  {
                    !activeLang &&
                    <Link
                      className='nav-link dropdown-toggle'
                      to={void 0}
                      id='language'
                      data-bs-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      <i className='fas fa-globe me-2'></i>
                      <span className='d-none d-lg-inline-block'>Language</span>
                    </Link>
                  }
                  {/* {
                    activeLang &&
                    <Link
                      className='nav-link dropdown-toggle'
                      to={void 0}
                      id='language'
                      data-bs-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      <Image
                        src={'/assets/images/flags/' + activeLangData.code + '.svg'}
                        alt={activeLangData.name[activeLang] || ''}
                        width={'20'}
                        height={'14'}
                        effect={'blur'}
                        classes={'fa-fw me-2'}
                      />
                      <span className='d-none d-lg-inline-block'>
                        {activeLangData.name[activeLang]}
                      </span>
                    </Link>
                  }
                  <ul
                    className='dropdown-menu dropdown-menu-end'
                    aria-labelledby='language'
                  >
                    {langs.map((item) => (
                      <li key={item._id}
                        onClick={() => {
                          dispatch(getActiveLang(item.code))
                          localStorage.setItem('lang', item.code)
                        }}
                      >
                        <Link className='dropdown-item d-flex' to={void 0}>
                          <Image
                            src={'/assets/images/flags/' + item.code + '.svg'}
                            alt={item.name[activeLang] || ''}
                            width={'20'}
                            height={'14'}
                            effect={'blur'}
                            classes={'fa-fw me-2'}
                          />
                          {item.name[activeLang] || ''}
                        </Link>
                      </li>
                    ))}
                  </ul> */}
                </li>
              </ul>
            )}


            {
              pageParams === "course-details-quiz" ?
                ""
                :
                <ul className='nav flex-row align-items-center list-unstyled ms-xl-auto userdropdown'>
                  {user ? (
                    <li className='nav-item dropdown'>
                      <Link
                        className='avatar avatar-sm p-0'
                        to={void 0}
                        id='profileDropdown'
                        role='button'
                        data-bs-auto-close='outside'
                        data-bs-display='static'
                        data-bs-toggle='dropdown'
                        aria-expanded={toggle}
                        onClick={() => setToggle(!toggle)}
                      >
                        <Image
                          src={userLogo}
                          alt={'avatar'}
                          width={'100%'}
                          height={'100%'}
                          effect={'blur'}
                          classes={'avatar-img rounded-circle'}
                        />
                      </Link>

                      <ul
                        className={`dropdown-menu dropdown-animation dropdown-menu-end shadow ${toggle ? "show" : ""}`}
                        aria-labelledby='profileDropdown'
                        ref={clickOutsideTrigger}
                      >
                        <li className='px-3'>
                          <div className='d-flex align-items-center'>
                            {/* <div className='avatar me-3'>
                              <Image
                                src={userLogo}
                                alt={'avatar'}
                                width={'100%'}
                                height={'100%'}
                                effect={'blur'}
                                classes={'avatar-img rounded-circle shadow'}
                              />
                            </div> */}
                            <div className="w-100">
                              <div className='username lh-sm m-0'>
                                {user?.is_company_owner ? user?.first_name : displayName}
                              </div>
                              <div className='small m-0'>{displayEmail}</div>
                            </div>
                          </div>
                        </li>
                        {userMenu.length > 0 && (
                          <>
                            <li>
                              <hr className='dropdown-divider' />
                            </li>
                            {userMenu.map((item) => (
                              <li key={item._id}>
                                {item.type === 'link' && (
                                  <NavLink
                                    to={item.link}
                                    className={'dropdown-item ' + item.classes}
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    <i className={'fa-fw me-2 ' + item.icon}></i>
                                    {item.name}
                                  </NavLink>
                                )}
                                {item.type === 'button' && (
                                  <button
                                    type='button'
                                    className={'dropdown-item ' + item.classes}
                                    onClick={() => {
                                      logout()
                                      setToggle(!toggle)
                                    }}
                                  >
                                    <i className={'fa-fw me-2 ' + item.icon}></i>
                                    {item.name}
                                  </button>
                                )}
                              </li>
                            ))}
                          </>
                        )}
                      </ul>
                    </li>
                  ) : (
                    <li className='nav-item'>
                      <NavLink to={'/sign-in'} className='nav-link btn-primary-soft btn-signin'>
                        <span className='icon me-1'>
                          <i className="fas fa-fw fa-sign-in-alt"></i>
                        </span>
                        <span className="text">{staticData?.login[activeLang]}</span>
                      </NavLink>
                    </li>
                  )}
                </ul>
            }


          </div>
        </nav>
      </header >
    </>
  );
};

export default FrontHeader;
