import React from 'react'

const BannerLoader = () => {
  return (
    <div>
        <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-35 loader-w-324 mb-2 rounded'></div>
        <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-75 loader-w-424 my-3 rounded'></div>
        <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-35 loader-w-324 mb-2 rounded'></div>
        <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-35 loader-w-424 mb-2 rounded'></div>
    </div>
  )
}

export default BannerLoader