import React, { useState, useEffect } from "react";
import toastr from "toastr";

import { postData } from "../../services/api";
import { useSelector } from "react-redux";

const EditProfileForm = () => {
  const activeLang = useSelector((state) => state.lang.activeLang)
  const staticData = useSelector((state) => state.langActionStaticData.staticSiteData)
  const [profileData, setProfileData] = useState({});
  const [idCode, setIdCode] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const userType = useSelector((state) => state.auth?.user?.role_details.code);
  const user = useSelector((state) => state.auth?.user);



  const onSubmit = async () => {
    setIsUpdate(true);
    try {
      const userData = await postData("profile/update", {
        name: name,
        last_name: lastName,
        email: email
      });
      if (userData.status && userData.status === 200) {
        toastr.success(userData.message);
        setIsUpdate(false);
      } else {
        toastr.error(userData.message);
        setIsUpdate(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsUpdate(false);
    }
  }

  useEffect(() => {
    async function profileDetails() {
      try {
        const result = await postData("profile/details", {});
        if (result.data) {
          setProfileData(result.data);
        } else {
          console.log('profile data', result.message)
        }
      } catch (error) {
        console.log('Error profile catch', error.message)
      }
    }
    profileDetails();
  }, []);

  useEffect(() => {
    if (profileData && profileData._id) {
      setName(profileData?.name);
      setLastName(profileData?.last_name);
      setEmail(profileData?.email);
      setIdCode(profileData?.id_code)
    }
  }, [profileData]);



  return (
    <>
      <div className="col-xl-9">
        <div className="card bg-white border rounded-3">
          <div className="card-header bg-white border-bottom">
            <h3 className="card-header-title mb-0">{staticData.editProfile.heading[activeLang]}</h3>
          </div>
          <div className="card-body">
            <form className="row g-4">
              {
                (userType !== "company" || user?.is_company_owner) &&
                <div className="col-md-12">
                  <label className="form-label">{staticData.editProfile.IDCode[activeLang]}</label>
                  <div className="input-group">
                    <span className="input-group-text">ID</span>
                    <input
                      type="number"
                      className="form-control"
                      value={idCode}
                      onChange={(e) => setIdCode(e.target.value)}
                      disabled />
                  </div>
                </div>
              }
              <div className="col-6">
                <label className="form-label">{staticData.editProfile.FirstName[activeLang]}</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    value={user?.is_company_owner ? user?.first_name : name}
                    placeholder={staticData.editProfile.FirstName[activeLang]}
                    onChange={(e) => setName(e.target.value)}
                    disabled
                  />
                </div>
              </div>
              <div className="col-6">
                <label className="form-label">{staticData.editProfile.LastName[activeLang]}</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    value={lastName}
                    placeholder={staticData.editProfile.LastName[activeLang]}
                    onChange={(e) => setLastName(e.target.value)}
                    disabled
                  />
                </div>
              </div>

              <div className="col-md-12">
                <label className="form-label">{staticData.editProfile.emailId[activeLang]}</label>
                <input
                  className="form-control"
                  type="email"
                  value={email}
                  placeholder={staticData.editProfile.emailId[activeLang]}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="d-sm-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-primary mb-0"
                  onClick={onSubmit}
                  disabled={isUpdate}
                ><i className={isUpdate ? 'fa fa-spinner fa-spin' : 'fa fa-check'}></i> {staticData.editProfile.saveBtn[activeLang]}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfileForm;
