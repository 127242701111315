import React from 'react'

const VideoBoxLoader = () => {
  return (
    <div>
        <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-240 w-full mb-2 rounded'></div>
    </div>
  )
}

export default VideoBoxLoader