import React, { useEffect } from 'react';
import PageBanner from '../components/pagesections/PageBanner';
import PrivacyContent from '../components/pagesections/PrivacyContent';
import { useDispatch, useSelector } from 'react-redux';
import { postData } from '../services/api';
import { pageSecData } from '../store/pageSlice';

const Privacy = () => {
  const dispatch = useDispatch()
  const privacyData = useSelector(state => state.pageData.pageData.privacy)
  const activeLang = useSelector((state) => state.lang.activeLang)


  // privecy policy sec development =============>

  useEffect(() => {
    const loadCourses = async () => {
      const res = await postData('page/details', {
        "alias": "privacy-policy"
      })
      if (res.status === 200) {
        dispatch(pageSecData({ privacy: res.data }))
      } else {
        console.log("can't fetch page data")
      }
    }
    loadCourses()
  }, [dispatch])


  const bannerData = [
    {
      _id: 1,
      title: privacyData?.name,
      subText: privacyData?.sub_title
    }

  ]
  return (
    <>
      <PageBanner data={bannerData} />
      <PrivacyContent data={privacyData?.description ? (privacyData?.description[activeLang] || "") : ""} />
    </>
  );
};

export default Privacy;