import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EditProfileSideMenu from './EditProfileSideMenu';
import CourseTable from './Dashboard/CourseTable';
import UserTable from './Dashboard/UserTable';
import UserTableMod from './Dashboard/UserTableMod';
import { postData } from '../../services/api';
import { NO_IMAGE } from '../../config/host';
import AddUserBtn from './Dashboard/AddUserBtn';
import AddUserModal from '../modal/AddUserModal';
import AddUserForm from '../form/AddUserForm';
import CourseTableLoader from '../Loader/CourseTableLoader';
import UserTableLoader from '../Loader/UserTableLoader';
import NoResult from '../elements/NoResult';
import toastr from 'toastr';

const DashboardSec = ({ profileSideMenu, user }) => {
  const navigate = useNavigate()
  const activeLang = useSelector((state) => state.lang.activeLang)
  const staticData = useSelector((state) => state.langActionStaticData.staticSiteData)
  const { myCourseList, loading: myCourseLoading } = useSelector((state) => state.myCourseListData);
  const [modalType, setModalType] = useState();
  const [userType, setuserType] = useState(user?.role_details?.code);
  const [userData, setUserData] = useState({})
  const [editUserId, setEditUserId] = useState()
  const [, setModalClose] = useState(false)
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false)

  // if company login=============>
  const [myUserList, setMyUserList] = useState({
    loading: true,
    data: [],
    count: 0,
  })
  const [userLimitData, setUserLimitData] = useState([])

  const userLimit = user? Math.round(user.user_limit) : 0

  useEffect(() => {
    if (editUserId) {
      setUserData(myUserList?.data.find((item) => item._id === editUserId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editUserId])

  useEffect(() => {
    if (user?.role_details?.code === "company") {
      const myUserData = async () => {
        setMyUserList((list) => ({ ...list, loading: true }));
        const res = await postData('user/list', {
          company_id: user?._id,
        });

        if (res.status === 200) {
          if (!res.data.length && !user?.is_company_owner) {
            navigate('/manage-users')
          }

          setMyUserList((list) => ({
            ...list,
            data: res.data,
            loading: false,
            count: res.count,
          }));
        } else {
          setMyUserList((list) => ({ ...list, loading: false }));
        }
      }
      myUserData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType])

  useEffect(() => {
    setuserType(user?.role_details?.code);
  }, [user?.role_details?.code])

  const closeModal = () => {
    setModalClose(false)
    setEditUserId()
    setUserData({})
  }

  useEffect(() => {
    if (userLimit && !myUserList.loading && myUserList.data.length < userLimit && window.location.pathname === '/my-course') {
      navigate('/manage-users') 
    }

    if (userLimit) {
      const users = myUserList.data
      
      setUserLimitData(data => Array(userLimit).fill().map((_, i) => {
        if (users[i]) {
          return users[i]
        } else {
          return {
            name: '', last_name: '', email: '', id_code: ''
          }
        }
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ userLimit, myUserList.data.length ])

  const onUserDataChange = (key, value, index) => {
    setUserLimitData(data => data.map((item, i) => index !== i ? item : {
      ...item,
      [key]: value
    }))
  }

  const onUserDataSubmit = async () => {
    setConfirmButtonDisabled(true)

    const res = await postData('my-user/manage', {
      users: userLimitData
    })

    if (res.status === 200) {
      setUserLimitData(data => Array(userLimit || 0).fill().map((_, i) => {
        if (res.data[i]) {
          return res.data[i]
        } else {
          return {
            name: '', last_name: '', email: '', id_code: ''
          }
        }
      }))

      setMyUserList(data => ({
        ...data,
        data: res.data,
        count: res.data.length
      }))
      toastr.success(res.message)

      setConfirmButtonDisabled(false)

      if (res.data.length > 0) {
        navigate('/my-course')
      }
    } else {
      toastr.error(res.message)
      setConfirmButtonDisabled(false)
    }
  }

  const limitDataMod = window.location.pathname === '/my-course' ? (
    userLimitData.filter(item => window.location.pathname === '/my-course' ? (item?._id?.length > 0 && item?.email?.length > 0) : item !== null)
  ) : userLimitData

  return (
    <section className='pt-0 bg-light section-min-height'>
      <div className='container'>
        <div className='row'>

          <EditProfileSideMenu menu={profileSideMenu} />

          <div className='col-xl-9 '>
            <div className='card bg-white border rounded-3'>
              <div className='card-header bg-white border-bottom d-flex justify-content-between flex-wrap'>
                <h3 className='mb-0'>{(user?.role_details?.code === "company") ? staticData.userListTable.heading[activeLang] : staticData.myCourseList[activeLang]}</h3>
                {
                  (user?.role_details?.code === "company" && !user?.is_company_owner) ?
                    <AddUserBtn
                      modalType={modalType}
                      setModalType={setModalType}
                      setModalClose={setModalClose}
                    />
                    :
                    ""
                }
              </div>

              <div className='card-body'>
                {
                  (

                    (
                      (userType === "company" && myUserList.loading) ? <UserTableLoader /> :
                      (userType !== "company" && myCourseLoading) ? <CourseTableLoader /> : ''
                    )
                  )
                }

                {

                  (userType === "company" && !user?.is_company_owner && !myUserList.loading && myUserList?.data?.length === 0 && (!userLimit || window.location.pathname === '/my-course')) ?
                    <NoResult /> : (userType === "company" && !myUserList.loading && userLimitData.length > 0 && !user?.is_company_owner) ?
                      (
                        <div className="userlist">
                          {
                            limitDataMod.map((list, i) =>
                              // myUserList.data.length === userLimit ?
                              window.location.pathname === '/my-course' ?
                              <UserTable 
                                key={list._id}
                                idCode={list.id_code}
                                userId={list._id}
                                setEditUserId={setEditUserId}
                                email={list.email}
                                name={list.name}
                                lastName={list.last_name}
                                phone={list.phone}
                                image={list.image}
                                modalType={modalType}
                                setModalType={setModalType}
                                setModalClose={setModalClose}
                              /> : <UserTableMod
                                key={list._id}
                                id={list._id}
                                index={i}
                                idCode={list.id_code}
                                userId={list._id}
                                setEditUserId={setEditUserId}
                                email={list.email}
                                name={list.name}
                                lastName={list.last_name}
                                phone={list.phone}
                                image={list.image}
                                modalType={modalType}
                                setModalType={setModalType}
                                setModalClose={setModalClose}
                                onUserDataChange={onUserDataChange}
                              />
                            )
                          }
                          {
                           ((limitDataMod !== userLimit) && (window.location.pathname !== '/my-course')) &&
                            <div className="px-3 pt-2">
                              <div className='btn-sec d-flex'>
                                  <button type='button' className='btn btn-primary ms-auto' disabled={confirmButtonDisabled} onClick={onUserDataSubmit}>Kinnita</button>
                              </div>
                            </div>
                          }
                        </div>
                      ) : <></>

                }

                {
                  ((userType === "user" || user?.is_company_owner) && !myCourseLoading && myCourseList?.length === 0) ?
                    <NoResult /> : ((userType === "user" || user?.is_company_owner) && !myCourseLoading && myCourseList?.length > 0) ?
                      (
                        <div className="courseslist">
                          {
                            myCourseList?.map((list) => (
                              <CourseTable
                                course={list}
                                key={list._id}
                                image={list.image || NO_IMAGE}
                                courseName={list.title[activeLang]}
                                courseId={list._id}
                                link={list._id}
                                id={list._id}
                                percentage={list.percentage}
                                totalLectures={list.total_lectures}
                                completedLectures={list.completed_lectures}
                                comingSoon={list.coming_soon}
                              />
                            ))
                          }
                        </div>
                      ) : <></>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        <AddUserModal
          userData={userData}
          editUserId={editUserId}
          list={myUserList}
          setMyUserList={setMyUserList}
          title={(modalType === "add") ? "Lisa kasutaja" : "Redigeeri kasutajat"}
          setModalClose={setModalClose}
          functions={closeModal}
        >
          <AddUserForm
            modalType={modalType}
            userData={userData}
            setUserData={setUserData}
          />
        </AddUserModal>
      }
    </section>
  );
};

export default DashboardSec;
