import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import VideoDuration from "../../elements/VideoDuration";

const InstructorItem = ({ video = {} }) => {
  const user = useSelector((state) => state.auth?.user);
  const activeLang = useSelector(state => state.lang.activeLang)
  const courseDetails = useSelector(state => state.course.details.data)

  return (
    <div className='d-flex justify-content-between align-items-center mb-3'>
      <div className='position-relative d-flex align-items-center'>
        {
          user?._id ?
            <Link
              to={'/course-video-player/' + courseDetails?._id + '?videoId=' + video?._id}
              className='btn btn-danger-soft btn-round btn-sm mb-0 stretched-link position-static'
            >
              <i className='fas fa-play me-0'></i>
            </Link> :
            <span
              className='btn btn-danger-soft btn-round btn-sm mb-0 stretched-div position-static'
            >
              <i className='fas fa-play me-0'></i>
            </span>
        }
        <span className='d-inline-block text-truncate ms-2 mb-0 h6 fw-light w-100px w-sm-200px w-md-400px'>
          {video.name ? (video.name[activeLang] || '') : ''}
        </span>
      </div>
      <p className='mb-0'>
        <VideoDuration ids={[video._id]} />
      </p>
    </div>
  );
};

export default InstructorItem;
