import React from 'react';
import Image from '../elements/Image';
import { Link } from 'react-router-dom';
import BannerLoader from '../Loader/BannerLoader';
import { useSelector } from 'react-redux';

const MainBanner = () => {
  const activeLang = useSelector((state) => state.lang.activeLang)
  const auth = useSelector((state) => state.auth.user)
  const bannerData = useSelector((state) => state.bannerData?.data)
  const staticData = useSelector((state)=>state.langActionStaticData.staticSiteData)


  const handleClickScroll = () => {
    const element = document.getElementById('HowToOrder');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };


  return (
    <>
      <section className="position-relative banner-section" style={{ backgroundImage: `url(${bannerData?.image})`, backgroundRepeat: ` no-repeat`, backgroundPosition: ' center center', backgroundSize: 'cover' }}>
        <div className="container position-relative mt-0 mt-sm-5 pt-5">
          <div className="row align-items-center">
            <div className="col-md-7">
              {
                Object.keys(bannerData).length > 0 ?
                  <>
                    <h4 className="sub-text">{bannerData?.title ? bannerData.title[activeLang] : ""}</h4>
                    <div dangerouslySetInnerHTML={{ __html: bannerData?.description ? bannerData.description[activeLang] : "" }}></div>
                    <div className="banner-action">
                    <button type='button' className="btn btn-primary btn-order" onClick={handleClickScroll}>{staticData.bannerButton[0].buttonOne[activeLang]}</button>
                    {
                      auth ?
                        "" :
                        <Link to='/sign-in' className="btn btn-outline-primary btn-order alt">{staticData.bannerButton[1].buttonTwo[activeLang]}</Link>
                    }
                    </div>
                  </>
                  :
                  <BannerLoader />
              }

              
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainBanner;