import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import VideoSection from '../components/pagesections/VideoSection';
import { postData } from '../services/api';
import {
  getCourseDetailsRequested,
  getCourseDetails,
  getCourseDetailsFailed,
  emptyCourseDetails,
  managePlayPauseVideo,
  clearPlayPauseVideo
} from '../store/courseSlice';
import FloatingBtn from '../components/pagesections/VideoDetails/FloatingBtn';
import NextButton from '../components/pagesections/VideoDetails/NextButton';

const VideoDetails = () => {
  const navigate = useNavigate()

  const [isPassed, setIsPassed] = useState(false);
  const [video, setVideo] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [showFloatingBtn, setFloatingBtn] = useState(false)
  const [showNextButton, setShowNextButton] = useState(false)

  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user)
  const courseDetails = useSelector((state) => state.course.details.data);

  const { id } = useParams();
  
  useEffect(() => {
    setFloatingBtn(false)
    setShowNextButton(false)
  }, [ video ])

  useEffect(() => {
    return () => {
      setVideo(null);
    };
  }, []);

  useEffect(() => {
    if (courseDetails?.video) {
      setVideo(new URL(courseDetails?.video).href);
    } else if (courseDetails?._id && !courseDetails?.video) {
      const firstCurriculum = courseDetails.curriculums[0];

      if (
        firstCurriculum &&
        firstCurriculum.videos &&
        firstCurriculum.videos[0]
      ) {
        dispatch(managePlayPauseVideo({
          videoId: firstCurriculum.videos[0]?._id,
          playing: true
        }))
        setVideo(new URL(firstCurriculum.videos[0]?.video).href);
      }
    }

    return () => {
      setVideo(null);
      dispatch(clearPlayPauseVideo())
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseDetails?.video]);

  useEffect(() => {
    const loadCourseDetails = async () => {
      if (!id) return;

      dispatch(getCourseDetailsRequested());
      const res = await postData('course/details', { id });
      if (res.status === 200) {
        if (res.data.coming_soon) {
          navigate('/')
        } else {
          dispatch(getCourseDetails(res.data));
        }
      } else {
        dispatch(getCourseDetailsFailed());
      }
    };

    loadCourseDetails();

    return () => {
      dispatch(emptyCourseDetails());
    };
  }, [id, dispatch]);

  return (
    <>
      {(user?.role_details?.code === 'user' || user?.is_company_owner) && !isPassed && playing && showFloatingBtn && (
        <FloatingBtn video={video} courseDetails={courseDetails} />
      )}
      {
        showNextButton && 
        <NextButton video={video} courseDetails={courseDetails} />
      }
      <VideoSection
        video={video}
        setVideo={setVideo}
        setIsPassed={setIsPassed}
        setPlaying={setPlaying}
        setFloatingBtn={setFloatingBtn}
        setShowNextButton={setShowNextButton}
      />
    </>
  );
};

export default VideoDetails;
