import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPlayer from "react-player";
import { managePlayPauseVideo } from "../../../store/courseSlice";
import { postData } from "../../../services/api";
import { getPrevVideo } from "../../shared/course";
// import { SEGMENT_SIZE } from '../../../config/host'
// import useS3PrivateFile from "../../../hooks/useS3PrivateFile";

const VideoPlayer = ({ video = null, sideBarToggle, setSideBarToggle, setPlaying = () => { }, setFloatingBtn = () => {}, setShowNextButton = () => {} }) => {
  const dispatch = useDispatch()
  const [selectedVideoIdData, setSelectedVideoIdData] = useState(null)
  const [selectedVideo, setSelectedVideo] = useState(null)
  const [videoDuration, setVideoDuration] = useState(null)
  const courseDetails = useSelector((state) => state.course.details.data);
  const [playedSeconds, setPlayedSeconds] = useState(0)
  const [nextVideo, setNextVideo] = useState(null)

  useEffect(() => {
    setVideoDuration(null)
    setPlayedSeconds(0)
    setNextVideo(null)
  }, [video])

  useEffect(() => {
    if (video) {
      let videoItem = null

      if (courseDetails?._id) {
        courseDetails?.curriculums.forEach((item, c) => {
          item.videos.forEach((videoData, i) => {
            if (
              video &&
              videoData.video && 
              !videoData.passed &&
              (new URL(videoData.video).href === new URL(video).href) 
            ) {
              videoItem = videoData
            }
          })
        })
      }

      if (videoItem) {
        setSelectedVideo(videoItem)
      }
    } else {
      setSelectedVideo(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ video, courseDetails?._id ])

  useEffect(() => {
    if (video) {
      if (courseDetails?._id) {
        courseDetails?.curriculums.forEach(item => {
          item.videos.forEach(videoData => {
            if (
              video &&
              videoData.video && 
              (new URL(videoData.video).href === new URL(video).href) 
            ) {
              setSelectedVideoIdData(videoData)
            }
          })
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ video, courseDetails ])

  // Fetch video duration
  useEffect(() => {
    if (selectedVideoIdData?._id) {
      const getVideoDuration = async () => {
        const res = await postData('video-duration/get', {
          videoIds: [selectedVideoIdData?._id]
        })
  
        if (res.status === 200) {
          setVideoDuration(res.data)
        }
      }

      getVideoDuration()
    }
  }, [ selectedVideoIdData ])

  useEffect(() => {
    if (
      typeof videoDuration === 'number' &&
      (videoDuration - playedSeconds) <= 5
    ) {
      setFloatingBtn(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoDuration, playedSeconds])

  useEffect(() => {
    if (courseDetails?._id) {
      courseDetails?.curriculums.forEach((item, c) => {
        item.videos.forEach((videoData, i) => {
          if (
            videoData.video && 
            selectedVideoIdData?._id === videoData?._id
          ) {
            const nextVideoItem = item.videos[i + 1] || (
              courseDetails.curriculums[c + 1] ? 
                courseDetails.curriculums[c + 1] ? 
                (courseDetails.curriculums[c + 1].videos[0] || null) : null
                : null
            )

            if (nextVideoItem) {
              const currentVideoData = getPrevVideo(videoData, courseDetails)
              const nextVideoData = getPrevVideo(nextVideoItem, courseDetails)
              if (
                nextVideoData.playable && 
                (videoData.passed || !currentVideoData.quizable)
              ) {
                setNextVideo(nextVideoItem)
              }
            }
          }
        })
      })
    }
  }, [courseDetails, selectedVideoIdData])

  useEffect(() => {
    if (
      typeof videoDuration === 'number' &&
      (videoDuration - playedSeconds) <= 5 &&
      nextVideo
    ) {
      setShowNextButton(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ videoDuration, playedSeconds ])

  return (
    <div className={`overflow-hidden video-container fullscreen-video w-100 ${sideBarToggle ? "active" : ""}`}>
      <div className='video-player rounded-3'>
        {
          video &&
          <ReactPlayer url={video || ''} playing={selectedVideoIdData?.playing || false} style={{objectFit:'contain', objectPosition:'center'}} height='100vh' width="100%" controls autoPlay
            onPause={() => dispatch(managePlayPauseVideo({
              videoId: selectedVideoIdData?._id,
              playing: false
            }))}
            onPlay={() => dispatch(managePlayPauseVideo({
              videoId: selectedVideoIdData?._id,
              playing: true
            }))}
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload',
                  onContextMenu: e => e.preventDefault()
                }
              }
            }}
            onProgress={(e) => {
              if (e?.loadedSeconds > 0) {
                setPlayedSeconds(e?.playedSeconds || 0)
                if (selectedVideo?.quizzes.length > 0) {
                  setPlaying(true)
                }
              }
            }}
          />
        }
      </div>
    </div>
  );
};

export default VideoPlayer;
