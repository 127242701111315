import { combineReducers } from "redux";
import { courseSlice } from "./courseSlice";
import { authSlice } from "./authSlice";
import { langSlice } from "./langSlice";
import testimonialsSlice from "./testimonialsSlice";
import siteSettingsSlice from "./siteSettingsSlice";
import pageSlice from "./pageSlice";
import myCourseSlice from "./myCourseSlice";
import bannerDataSlice from "./bannerDataSlice";
import myUserSlice from "./myUserSlice";
import langActionSlice from "./langActionSlice";

export default combineReducers({
  course: courseSlice.reducer,
  auth: authSlice.reducer,
  lang: langSlice.reducer,
  testimonial:testimonialsSlice,
  siteSettings: siteSettingsSlice,
  pageData:pageSlice,
  myCourseListData: myCourseSlice,
  bannerData:bannerDataSlice,
  userDataList:myUserSlice,
  langActionStaticData: langActionSlice,
})