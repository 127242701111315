import axios from "axios";
import { API_URL, FILE_API_URL } from "../config/host";

export const postData = async (url = '', data = {}) => {
  try {
    const token = localStorage.getItem('accessToken')
    const headers = {}

    if (token) {
      headers.Authorization = `Bearer ${token}`
    }

    const instance = axios.create({
      baseURL: API_URL,
      headers
    });

    const res = await instance.post(url, data);
    return res.data;
  } catch (error) {
    return { status: 400, message: error.message };
  }
};

export const getS3FileDetails = async (url) => {
  try {
    const res = await fetch(FILE_API_URL + url);
    const blob = await res.blob()

    return {
      status: 200,
      data: URL.createObjectURL(new Blob([blob])),
    };
  } catch (err) {
    return { status: 400, message: err.message };
  }
};