import { useState } from 'react';
import VideoPlayer from './CourseVideoPlayerSection/VideoPlayer';
import CourseVideoCurrculum from './CourseVideoPlayerSection/CourseVideoCurrculum';

const VideoSection = ({ video, setVideo, setIsPassed, setPlaying, setFloatingBtn = () => {}, setShowNextButton = () => {} }) => {
  const [sideBarToggle, setSideBarToggle] = useState(false);
  return (
    <section className='py-0 bg-dark position-relative vh-100 video-section-part'>
      <div className='row g-0'>
        <div className='d-flex'>
          <VideoPlayer video={video} sideBarToggle={sideBarToggle} setSideBarToggle={setSideBarToggle} setPlaying={setPlaying} setFloatingBtn={setFloatingBtn} setShowNextButton={setShowNextButton} />
          <CourseVideoCurrculum video={video} setVideo={setVideo} sideBarToggle={sideBarToggle} setSideBarToggle={setSideBarToggle} setIsPassed={setIsPassed} 
            setPlaying={setPlaying}
          />
        </div>
      </div>
    </section>
  );
};

export default VideoSection;
