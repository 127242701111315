import React from 'react'

const Details = ({ title, location, phone, email, backgroundColor, color }) => {
    return (
        <>
            <div className={`data-row ${backgroundColor}`}>
                <div className={`data-title ${color}`}>{title}</div>
                {
                    location ?
                        <div className="data-item">
                            <div className="icon">
                                <i className="fas fa-map-marker-alt"></i>
                            </div>
                            <div className={`text ${color}`}>{location}</div>
                        </div>
                        :
                        null
                }
                {
                    phone ?
                        <div className="data-item">
                            <div className="icon ">
                                <i className="fas fa-phone-alt"></i>
                            </div>
                            <div className={`text ${color}`}>{phone}</div>
                        </div>
                        :
                        null
                }
                {
                    email && <div className="data-item">
                        <div className="icon">
                            <i className="fas fa-envelope"></i>
                        </div>
                        <div className={`text ${color}`}>{email}</div>
                    </div>
                }
            </div>
        </>
    )
}

export default Details