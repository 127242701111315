import { useEffect } from "react";

const useOnClickOutside = (ref, handler, params = {}, altRef) => {
  return (
    useEffect(() => {
        const listener = (event) => {
            if (!ref || !ref.current || ref.current.contains(event.target)) {
                return;
            }

            if (altRef && altRef.current && altRef.current.contains(event.target))  {
                return 
            }

            handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [ref, handler, params.flatpickerRef, altRef])
  )
}

export default useOnClickOutside