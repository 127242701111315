import { createSlice, createAction } from "@reduxjs/toolkit";

export const langRequested = createAction('langRequested')
export const langResponse = createAction('langResponse')
export const langError = createAction('langError')
export const getActiveLang = createAction('getActiveLang')

export const langSlice = createSlice({
  name: 'lang',
  initialState: {
    list: {
      loading: false,
      data: []
    },
    activeLang: localStorage.getItem('lang') || 'en'
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(langRequested, (state, action) => {
        state.list.loading = true
      })
      .addCase(langResponse, (state, action) => {
        state.list.loading = false
        state.list.data = action.payload
      })
      .addCase(langError, (state, action) => {
        state.list.loading = false
      })
      .addCase(getActiveLang, (state, action) => {
        state.activeLang = action.payload
      })
  }
})