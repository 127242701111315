import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { NO_IMAGE } from '../../config/host';

const Image = ({src, width, height, alt, effect, classes}) => {
  const [isValidImage, setIsValidImage] = useState(true)

  return (
    <>
      <LazyLoadImage
        src={(!isValidImage || !src) ? NO_IMAGE : src}
        width={width} 
        height={height}
        alt={alt}
        effect={effect}
        className={(classes)}
        onError={() => setIsValidImage(false)}
      />
    </>
  );
};

export default Image;