import React from 'react'

const HowItWorkLoader = props => (
    <div className="col-lg-6 position-relative order-2">
        <div className="image-part bg-light">

        </div>
    </div>
)

export default HowItWorkLoader