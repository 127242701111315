import { useSelector } from 'react-redux';
// import Image from '../../elements/Image';

const CourseVideoIntroPlayer = () => {
  const courseDetails = useSelector((state) => state.course.details.data);

  return (
    <div className='col-12 position-relative'>
      <div className='video-player rounded-3'>
        {/* {
          !courseDetails.video &&
          <Image 
            width="100%"
            alt={courseDetails?.title}
            src={courseDetails?.image}
          />
        } */}
        {(courseDetails?.image && courseDetails?.video) && (
          <video
            controls
            crossorigin='anonymous'
            playsinline
            // poster={courseDetails?.image}
          >
            <source src={courseDetails?.video} />
          </video>
        )}
      </div>
    </div>
  );
};

export default CourseVideoIntroPlayer;
