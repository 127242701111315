import { useSelector } from "react-redux"

const AddUserForm = ({ modalType, userData, setUserData = () => { } }) => {
    const activeLang = useSelector((state) => state.lang.activeLang)
    const staticData = useSelector((state) => state.langActionStaticData.staticSiteData)
    const inputHandler = (e) => {
        const value = e.target.value
        const name = e.target.name
        setUserData((preState) => ({ ...preState, [name]: value }))
    }

    return (
        <form>
            <div className='d-flex justify-content-between gap-2 align-items-center'>
                <div className='input-field w-50 mb-1'>
                    <label className='w-100 form-label'>
                        <span className='d-block'>{staticData.editProfile.FirstName[activeLang]}</span>
                        <input type='text' name="name" value={userData?.name || ""} className='w-100 bg-light-input form-control' disabled={modalType === "edit" && true} onChange={inputHandler} />
                    </label>
                </div>
                <div className='input-field w-50 mb-1'>
                    <label className='w-100 form-label'>
                        <span className='d-block'>{staticData.editProfile.LastName[activeLang]}</span>
                        <input type='text' name="last_name" value={userData?.last_name || ""} className='w-100 bg-light-input form-control' disabled={modalType === "edit" && true} onChange={inputHandler} />
                    </label>
                </div>
            </div>
            <div className='d-flex justify-content-between gap-2 align-items-center'>
                <div className='input-field w-50 mb-1'>
                    <label className='w-100 form-label'>
                        <span className='d-block'>{staticData.editProfile.emailId[activeLang]}</span>
                        <input type='email' name="email" value={userData?.email || ""} className='w-100 bg-light-input form-control' onChange={inputHandler} />
                    </label>
                </div>
                <div className='input-field w-50 mb-1'>
                    <label className='w-100 form-label'>
                        <span className='d-block'>{staticData.userListTable.phone[activeLang]}</span>
                        <input type='tel' name="phone" value={userData?.phone || ""} className='w-100 bg-light-input form-control' onChange={inputHandler} />
                    </label>
                </div>
            </div>
            <div className='input-field w-100 mb-1'>
                <label className='w-100 form-label'>
                    <span className='d-block'>{staticData.editProfile.IDCode[activeLang]}</span>
                    <input type='text' name="id_code" value={userData?.id_code || ""} className='w-100 bg-light-input form-control' disabled={modalType === "edit" && true} onChange={inputHandler} />
                </label>
            </div>
        </form>
    )
}

export default AddUserForm