import { createSlice, createAction } from "@reduxjs/toolkit";

export const getCourseListRequested = createAction('getCourseListRequested')
export const getCourseList = createAction('getCourseList')
export const getCourseListFailed = createAction('getCourseListFailed')

export const getCourseDetailsRequested = createAction('getCourseDetailsRequested')
export const getCourseDetails = createAction('getCourseDetails')
export const getCourseDetailsFailed = createAction('getCourseDetailsFailed')
export const emptyCourseDetails = createAction('emptyCourseDetails')

export const getCourseQuizRequested = createAction('getCourseQuizRequested')
export const getCourseQuiz = createAction('getCourseQuiz')
export const getCourseQuizFailed = createAction('getCourseQuizFailed')
export const emptyCourseQuiz = createAction('emptyCourseQuiz')

export const managePlayPauseVideo = createAction('managePlayPauseVideo')
export const clearPlayPauseVideo = createAction('clearPlayPauseVideo')

export const setAnswer = createAction('setAnswer')

export const courseSlice = createSlice({
  name: "courses",
  initialState: {
    list: {
      loading: false,
      data: [],
    },
    details: {
      loading: false,
      data: {}
    },
    quiz: {
      loading: false,
      data: []
    }
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCourseListRequested, (state, action) => {
        state.list.loading = true
      })
      .addCase(getCourseList, (state, action) => {
        state.list.loading = false
        state.list.data = action.payload
      })
      .addCase(getCourseListFailed, (state, action) => {
        state.list.loading = false
      })
      .addCase(getCourseDetailsRequested, (state, action) => {
        state.details.loading = true
      })
      .addCase(getCourseDetails, (state, action) => {
        state.details.loading = false
        state.details.data = action.payload
      })
      .addCase(getCourseDetailsFailed, (state, action) => {
        state.details.loading = false
      })
      .addCase(emptyCourseDetails, (state, action) => {
        state.details.loading = false
        state.details.data = {}
      })
      .addCase(getCourseQuizRequested, (state, action) => {
        state.quiz.loading = true
      })
      .addCase(getCourseQuiz, (state, action) => {
        state.quiz.loading = false
        state.quiz.data = action.payload
      })
      .addCase(getCourseQuizFailed, (state, action) => {
        state.quiz.loading = false
      })
      .addCase(emptyCourseQuiz, (state, action) => {
        state.quiz.loading = false
        state.quiz.data = []
      })
      .addCase(setAnswer, (state, action) => {
        state.quiz.data = state.quiz.data.map(item => (
          item._id !== action.payload.qId ? item : {
            ...item,
            answer: item.answer.map(answer => (
              answer._id !== action.payload.ansId ? {
                ...answer,
                checked: false
              } : {
                ...answer,
                checked: answer.checked ? false : true
              }
            ))
          }
        ))
      })
      .addCase(managePlayPauseVideo, (state, action) => {
        const videoId = action.payload.videoId

        if (state.details.data?._id && videoId) {
          state.details.data = {
            ...state.details.data,
            curriculums: state.details.data.curriculums.map(curriculum => ({
              ...curriculum,
              videos: curriculum.videos.map(video => video._id !== videoId ? {
                ...video,
                playing: false,
              } : {
                ...video,
                playing: action.payload.playing,
              })
            }))
          }
        }
      })
      .addCase(clearPlayPauseVideo, (state, action) => {
        if (state.details.data?._id) {
          state.details.data = {
            ...state.details.data,
            curriculums: state.details.data.curriculums.map(curriculum => ({
              ...curriculum,
              videos: curriculum.videos.map(video => ({
                ...video,
                playing: false,
              }))
            }))
          }
        }
      })
  }
});