import React from "react";
import { Link } from "react-router-dom";
import LeftBanner from "./HowItWorks/LeftBanner";
import CourseDefinations from "./HowItWorks/CourseDefinations";
import GetInTouch from "../items/GetInTouch";
import { useSelector } from "react-redux";
import HowItWorkLoader from "../Loader/HowItWorkLoader";
import HeadingParagraphLoader from "../Loader/HeadingParagraphLoader";
import CoursePartLoader from "../Loader/CoursePartLoader";

const HowItWorks = () => {
  const activeLang = useSelector((state) => state.lang.activeLang)
  const staticData = useSelector((state)=>state.langActionStaticData.staticSiteData)
  const howToOrder = useSelector((state) => state.pageData.pageData.howToOrder)

  
  return (
    <>
      <section className="py-9 how-it-works-section" id="HowToOrder">
        <div className="container">
          <div className="row g-4 g-lg-5 align-items-center">
            {
              howToOrder !== undefined ?
                <LeftBanner image={howToOrder?.image} />
                :
                <HowItWorkLoader />
            }
            <div className="col-lg-6 position-relative order-1 order-lg-2 ">
              <div className="content-part">
                {
                  howToOrder === undefined ?
                    <HeadingParagraphLoader />
                    :
                    <>
                      <h2>{howToOrder?.name ? (howToOrder?.name[activeLang] || '') : ''}</h2>
                      <p className="mb-2">
                        {howToOrder?.sub_title ? (howToOrder?.sub_title[activeLang] || '') : ''}
                      </p>
                    </>
                }
                {
                  howToOrder === undefined ?
                    <div className="course-part">
                      <CoursePartLoader />
                    </div>
                    :
                    <div className="course-part" dangerouslySetInnerHTML={{ __html: howToOrder?.description ? (howToOrder?.description[activeLang] || '') : '' }}>
                    </div>
                }
                <GetInTouch />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HowItWorks;
