import React from "react";
import { useSelector } from "react-redux";
import { formatDateTZ } from "../../../helpers/date";
import HeadingParagraphLoader from "../../Loader/HeadingParagraphLoader";
import CourseDetailsHeadingParagraphLoader from "../../Loader/CourseDetailsHeadingParagraphLoader";

const CourseDescription = () => {
  const activeLang = useSelector((state) => state.lang.activeLang);
  const courseDetails = useSelector((state) => state.course.details.data);
  const loading = useSelector((state) => state.course.details.loading);

  return (
    <>
      <h1>{courseDetails.title && (courseDetails.title[activeLang] || "")}</h1>
      <p>
        {courseDetails.sub_title && (courseDetails.sub_title[activeLang] || "")}
      </p>
      

      <ul className="list-inline mb-0 justify-content-between">
        {courseDetails.instructor?.name && (
          <li className="list-inline-item h6 mb-0">
            <i className="fas fa-user text-info me-2"></i>
            {courseDetails.instructor?.name || ""}
          </li>
        )}

        {/* {
                    courseDetails.updated_at &&
                    <li className='list-inline-item h6 ms-3 mb-1 mb-sm-0'>
                        <i className='bi bi-patch-exclamation-fill text-danger me-2'></i>
                        {
                            'Last updated ' + formatDateTZ(new Date(courseDetails.updated_at), 'MM/YYYY')
                        }
                    </li>
                } */}
      </ul>
    </>
  );
};

export default CourseDescription;
