import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CourseVideoCurriculumSections from './CourseVideoCurriculumSections';
// import { useEffect, useState } from 'react';

const CourseVideoCurrculum = ({ video, setVideo, sideBarToggle, setSideBarToggle, setIsPassed, setPlaying }) => {
  const auth = useSelector((state) => state.auth.user)
  const activeLang = useSelector(state => state.lang.activeLang)
  const courseDetails = useSelector(state => state.course.details.data)

  return (
    <div className={`sidebar-sec ${sideBarToggle? " active" : ''}`}>
      <button
        className='navbar-toggler btn btn-white mt-4 plyr-toggler'
        type='button'
        data-bs-toggle='collapse'
        // data-bs-target='#collapseWidthExample'
        aria-expanded={sideBarToggle}
        aria-controls='collapseWidthExample'
        onClick={()=>setSideBarToggle(!sideBarToggle)}
      >
        <span className='navbar-toggler-animation'>
          <span></span>
          <span></span>
          <span></span>
        </span>
      </button>

      <div className={`collapse collapse-horizontal show`} id='collapseWidthExample'>
        <div
          className='card vh-100 rounded-0 '
          style={{ overflow: `hidden auto` }}
        >
          <div className='card-header bg-light rounded-0'>
            <h1 className='mt-2 fs-5'>
              {courseDetails?.title ? (courseDetails?.title[activeLang] || '') : courseDetails?.title}
            </h1>
            <h6 className='mb-0 fw-normal'>
              <a href='#!'>By {courseDetails?.instructor?.name || ''}</a>
            </h6>
          </div>

          <div className='row g-4'>
            <div className='col-12'>
              <div className='card shadow rounded-3'>
                <div className='card-header border-bottom'>
                  <h3 className='mb-0'>Õppekava</h3>
                </div>
                <CourseVideoCurriculumSections activeVideo={video} setVideo={setVideo} 
                  setIsPassed={setIsPassed}
                  setPlaying={setPlaying}
                />
              </div>
            </div>
          </div>

          <div className='card-footer position-sticky'>
            <div className='d-grid'>
              <Link
                to={`${!auth?._id ? "/course-details/"+courseDetails._id:"/course-details-adv/"+courseDetails._id}`}
                className='btn btn-primary-soft mb-0'
              >
                Tagasi kursusele
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseVideoCurrculum;
