import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getCourseDetailsRequested,
  getCourseDetails,
  getCourseDetailsFailed,
  emptyCourseDetails,
} from '../store/courseSlice';
import { postData } from '../services/api';
import CourseDescription from '../components/pagesections/CourseDetailsSection/CourseDescription';
import CourseSideBar from '../components/pagesections/CourseSideBar';
import Overview from '../components/pagesections/CourseDetailsSection/Overview';
import CourseVideoIntroPlayer from '../components/pagesections/CourseVideoPlayerSection/CourseVideoIntroPlayer';
import TermsLoader from '../components/Loader/TermsLoader';
import CourseDetailsHeadingParagraphLoader from '../components/Loader/CourseDetailsHeadingParagraphLoader';
import MetaTitle from '../components/documenuts/MetaTitle';
// import MetaTitle from '../components/documenuts/MetaTitle';

const CourseDetailsAdv = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const navigate = useNavigate()
  const activeLang = useSelector(state => state.lang.activeLang)
  const courseDetails = useSelector((state) => state.course.details.data);
  const loading = useSelector(state => state.course.details.loading)


  useEffect(() => {
    const loadCourseDetails = async () => {
      if (!id) return;

      dispatch(getCourseDetailsRequested());
      const res = await postData('course/details', { id });
      if (res.status === 200) {
        if (res.data.coming_soon) {
          navigate('/')
        } else {
          dispatch(getCourseDetails(res.data));
        }
      } else {
        dispatch(getCourseDetailsFailed());
        navigate('/')
      }
    };

    loadCourseDetails();

    return () => {
      dispatch(emptyCourseDetails());
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch]);

  return (
    <>
      <MetaTitle 
        title={
          courseDetails?.title ? 
          `Radisol | ${courseDetails?.title[activeLang]}` : 'Radisol | Course'
        } 
      />
      {
        loading === true ?
          <section className='pt-3 pt-xl-5 bg-light'>
            <div className='container' data-sticky-container>
              <div className='row g-4'>
                <div className='col-xl-8'>
                  <div className='row g-4'>
                    <div className='col-12'>
                      <CourseDetailsHeadingParagraphLoader />
                    </div>
                    {/* video player sec */}
                    <div className='col-12'>
                      <div className='card shadow rounded-2 p-0 mb-4'>
                        <div className='card-body p-4'>
                          <div className='tab-content pt-2' id='course-pills-tabContent'>
                            <TermsLoader />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          :
          <section className='pt-3 pt-xl-5 bg-light'>
            <div className='container' data-sticky-container>
              <div className='row g-4'>
                <div className='col-xl-8'>
                  <div className='row g-4'>
                    <div className='col-12'>
                      <CourseDescription />
                    </div>
                    <CourseVideoIntroPlayer />
                    <div className='col-12'>
                      <div className='card shadow rounded-2 p-0 mb-4'>
                        <div className='card-body p-4'>
                          <div
                            className='tab-content pt-2'
                            id='course-pills-tabContent'
                          >
                            {
                              Object.keys(courseDetails).length > 0 ?
                                <Overview
                                  description={
                                    courseDetails.description ?
                                      (courseDetails.description[activeLang] || '') :
                                      ''
                                  }
                                /> :
                                <div
                                  className='tab-pane fade show active'
                                  id='course-pills-1'
                                  role='tabpanel'
                                  aria-labelledby='course-pills-tab-1'
                                >
                                  <TermsLoader />
                                </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-xl-4'>
                  <CourseSideBar />
                </div>
              </div>
            </div>
          </section>
      }
    </>
  );
};

export default CourseDetailsAdv;
