import React from "react";

const CourseListItem = ({ courseData }) => {
  return (
    <>
      {courseData.map((item) => (
        <div className="col-12" key={item._id}>
          <div className="card shadow overflow-hidden p-2">
            <div className="row g-0">
              <div className="col-md-5 overflow-hidden">
                <img src={item.courseImage} className="rounded-2" alt="Card image" />
                <div className="card-img-overlay">
                {item.type === "Free" &&
                  <div className="ribbon">
                    <span>{item.type}</span>
                  </div>
                }
                </div>
              </div>
              <div className="col-md-7">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <a href="#" className="badge text-bg-primary mb-2 mb-sm-0">
                      {item.tag}
                    </a>
                    <div>
                      <span className="h6 fw-light me-3">
                        <i className="fas fa-star text-warning me-1"></i>
                        {item.rating}
                      </span>
                      <a href="#" className="text-danger">
                        <i className="fas fa-heart"></i>
                      </a>
                    </div>
                  </div>

                  <h5 className="card-title">
                    <a href="course-detail.html">{item.name}</a>
                  </h5>
                  <p className="text-truncate-2 d-none d-lg-block">{item.description}</p>

                  <ul className="list-inline">
                    <li className="list-inline-item h6 fw-light mb-1 mb-sm-0">
                      <i className="far fa-clock text-danger me-2"></i>
                      {item.time}
                    </li>
                    <li className="list-inline-item h6 fw-light mb-1 mb-sm-0">
                      <i className="fas fa-table text-orange me-2"></i>
                      {item.lecture} Osa
                    </li>
                    <li className="list-inline-item h6 fw-light">
                      <i className="fas fa-signal text-success me-2"></i>
                      {item.level}
                    </li>
                  </ul>

                  <div className="d-sm-flex justify-content-sm-between align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="avatar">
                        <img className="avatar-img rounded-circle" src={item.userImage} alt="avatar" />
                      </div>
                      <p className="mb-0 ms-2">
                        <a href="#" className="h6 fw-light">
                          {item.authorName}
                        </a>
                      </p>
                    </div>

                    <div className="mt-3 mt-sm-0">
                      <a href="course-detail.html" className="btn btn-dark">
                        View more
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default CourseListItem;
