import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import MetaTitle from '../components/documenuts/MetaTitle';
import { useDispatch } from 'react-redux';
import { authLogin, authLogout, authSettingsSetup } from '../store/authSlice';
import { postData } from "../services/api";


const ProtectedRoute = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [auth, setAuth] = useState(false)
  let token_ = localStorage.getItem('accessToken');
  let activeToken_ = localStorage.getItem('activeToken');
  // const role =  localStorage.getItem("role");
  //const accessRole = ['master-admin',...props.getAccess];


  useEffect(() => {
    async function fetchData() {
      const settingsData = await postData("site-settings/details", {}, token_);
      const userData = await postData("verify-token", {}, token_);

      if (
        userData?.data && token_ && activeToken_ && 
        userData?.data?.active_token === activeToken_
      ) {
        if (userData?.status && userData?.status === 200) {
          setAuth(true);
          dispatch(authLogin(userData?.data));
          dispatch(authSettingsSetup(settingsData?.data));
          // navigate('/my-course')
        } else {
        //   setAuth(false);
        //   dispatch(authLogout());
        //   localStorage.removeItem('accessToken');
        //   localStorage.removeItem('accessToken');
        //   navigate('/sign-in')
        }
        return
      } else {
        setAuth(false);
        dispatch(authLogout());
        localStorage.removeItem('accessToken');
        localStorage.removeItem('activeToken');
        navigate('/')
        return
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, token_, activeToken_, window.location.pathname])


  // useEffect(() =>  {
  //   async function checkPath() {
  //     let checkRole = accessRole.filter(item => item === role);
  //     if(checkRole && checkRole.length === 0){
  //       if(role === 'master-admin'){
  //         return navigate('/dashboard-statistics')
  //       }else{
  //         return navigate('/dashboard')          
  //       }        
  //     }
  //   }
  //   checkPath();
  // }, [dispatch,window.location.pathname,props.getAccess])

  return (
    <>
      {
        props.title ?
        <MetaTitle title={`Radisol | ${props.title}`} />
        : ''
      }
      {
        (auth || props.firstLoad) && props.children
      }
    </>
  )
}

export default ProtectedRoute;


