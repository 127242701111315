import React from 'react'

const CourseListLoader = () => {
    return (
        <div className='d-flex gap-2'>

            <div className='text-loader bg-light loader-h-324 d-flex flex-column justify-content-end w-25 mb-3 rounded p-3'>

                <div className='text-loader w-full mb-3 rounded'>

                    <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-35 w-75 mb-3 rounded'></div>

                    <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-15 w-full mb-1 rounded'></div>

                    <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-15 w-50 rounded'></div>

                </div>
            </div>

            <div className='text-loader bg-light loader-h-324 d-flex flex-column justify-content-end w-25 mb-3 rounded p-3'>

                <div className='text-loader w-full mb-3 rounded'>

                    <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-35 w-75 mb-3 rounded'></div>

                    <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-15 w-full mb-1 rounded'></div>

                    <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-15 w-50 rounded'></div>

                </div>
            </div>

            <div className='text-loader bg-light loader-h-324 d-flex flex-column justify-content-end w-25 mb-3 rounded p-3'>

                <div className='text-loader w-full mb-3 rounded'>

                    <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-35 w-75 mb-3 rounded'></div>

                    <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-15 w-full mb-1 rounded'></div>

                    <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-15 w-50 rounded'></div>

                </div>
            </div>

            <div className='text-loader bg-light loader-h-324 d-flex flex-column justify-content-end w-25 mb-3 rounded p-3'>

                <div className='text-loader w-full mb-3 rounded'>

                    <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-35 w-75 mb-3 rounded'></div>

                    <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-15 w-full mb-1 rounded'></div>

                    <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-15 w-50 rounded'></div>

                </div>
            </div>

        </div>
    )
}

export default CourseListLoader