import { Link } from 'react-router-dom';
import { getPrevVideo } from '../../shared/course';

const NextButton = ({ courseDetails = {}, video }) => {
  const getVideoId = () => {
    let videoId
    courseDetails?.curriculums?.forEach((item, c) => {
      item.videos.forEach((videoItem, i) => {
        if (decodeURIComponent(videoItem.video) === decodeURIComponent(video)) {
          const nextVideoItem = item.videos[i + 1] || (
            courseDetails.curriculums[c + 1] ? 
              courseDetails.curriculums[c + 1] ? 
              (courseDetails.curriculums[c + 1].videos[0] || null) : null
              : null
          )

          if (nextVideoItem) {
            const currentVideoData = getPrevVideo(videoItem, courseDetails)
            const nextVideoData = getPrevVideo(nextVideoItem, courseDetails)
            if (
              nextVideoData.playable && 
              (videoItem.passed || !currentVideoData.quizable)
            ) {
              videoId = nextVideoItem._id
            }
          }
        }
      })
    })
    return videoId
  }

  if (!getVideoId()) return <></>

  return (
    <div className='floating-quiz p-3'>
      <button type='button' className='border-0'>
        <Link
          to={`/course-video-player/${courseDetails?._id}?videoId=${getVideoId()}`}
          onClick={(e) => {
            e.preventDefault()
            window.location.href = `/course-video-player/${courseDetails?._id}?videoId=${getVideoId()}`
          }}
          className='btn btn-sm btn-success mb-0 btn-attempt'
        >
          Järgmine loeng
        </Link>
      </button>
    </div>
  );
};

export default NextButton;
