import { PDFDownloadLink } from '@react-pdf/renderer';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ViewPdf from '../../../pages/ViewPdf';

const CourseTable = ({
  course = {},
  image,
  courseName,
  link,
  id,
  courseId,
  percentage,
  totalLectures,
  completedLectures,
  comingSoon = false,
}) => {
  const siteSettings = useSelector((state) => state?.siteSettings.data);
  const langs = useSelector((state) => state.lang.list.data);
  const defaultLang = (langs || []).find((item) => item.default)?.code || 'en';
  const activeLang = useSelector((state) => state.lang.activeLang);
  const staticData = useSelector(
    (state) => state.langActionStaticData.staticSiteData
  );
  const user = useSelector((state) => state.auth?.user);

  return (
    <>
      <div className='courseslist-item'>
        <div className='courseslist-info'>
          <div className='position-relative text-center main-box' >
            <div className='courseslist-image'>
              <img src={image} alt='' />
            </div>
            {
              comingSoon &&
              <button className='btn btn-success text-white text-center btn-custom'>Peagi lisandumas</button>
            }
          </div>
          
          <div className='courseslist-content'>
            <Link
              to={`/course-details-adv/${link}`}
              className='courseslist-title'
              onClick={(e) => {
                if (comingSoon) {
                  e.preventDefault()
                }
              }}
              style={{
                cursor: !comingSoon ? 'pointer' : 'not-allowed'
              }}
            >
              {courseName}
            </Link>
            {
              !comingSoon &&
              <>
                <div className='courseslist-meta'>
                  <div className='courseslist-meta-item'>
                    <div className='courseslist-meta-label'>Loenguid Kokku</div>
                    <div className='courseslist-meta-data'>
                      : {totalLectures}
                    </div>
                  </div>
                  <div className='courseslist-meta-item'>
                    <div className='courseslist-meta-label'>
                      Lõpetatud Loenguid
                    </div>
                    <div className='courseslist-meta-data'>
                      : {completedLectures}
                    </div>
                  </div>
                </div>
                <div className='courseslist-progress'>
                  <div className='progress'>
                    <div
                      className='progress-bar aos'
                      role='progressbar'
                      data-aos='slide-right'
                      data-aos-delay='200'
                      data-aos-duration='1000'
                      data-aos-easing='ease-in-out'
                      style={{ width: `${percentage}%` }}
                      aria-valuenow={`${percentage}%`}
                      aria-valuemin='0'
                      aria-valuemax='100'
                    ></div>
                  </div>
                  <div className='progress-percentage'>{`${percentage}%`}</div>
                </div>
              </>
            }
          </div>
        </div>
        {
          !comingSoon &&
          <div className='courseslist-options'>
            {(percentage === 100 && !(user?.is_company_owner && user?.user_limit === 1)) ? (
              <>
                <PDFDownloadLink
                  document={
                    <ViewPdf
                      siteSettings={siteSettings}
                      course={course}
                      defaultLang={defaultLang}
                    />
                  }
                  fileName='certificate.pdf'
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      <button
                        type='button'
                        className='btn-primary-soft btn-action'
                      >
                        <i className='fas fa-fw fa-download'></i>
                        <span>Loading...</span>
                      </button>
                    ) : (
                      <button
                        type='button'
                        className='btn-primary-soft btn-action'
                      >
                        <i className='fas fa-fw fa-download'></i>
                        <span>
                          {staticData.dashboardBtn.certificateBtn[activeLang]}
                        </span>
                      </button>
                    )
                  }
                </PDFDownloadLink>
                <button type='button' className='btn-success-soft btn-action'>
                  <i className='fas fa-fw fa-check-square'></i>
                  <span>{staticData.dashboardBtn.completeBtn[activeLang]}</span>
                </button>
              </>
            ) : (
              <Link
                to={'/course-video-player/' + id}
                className='btn-danger-soft btn-action'
              >
                <i className='fas fa-fw fa-play-circle'></i>
                <span>{staticData.dashboardBtn.continueBtn[activeLang]}</span>
              </Link>
            )}
          </div>
        }
        
      </div>
    </>
  );
};

export default CourseTable;
