import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import FrontFooter from "../shared/FrontFooter";
import FrontHeader from '../shared/FrontHeader';
import QuizDetailsFooter from "../shared/QuizDetailsFooter"
import MetaTitle from '../components/documenuts/MetaTitle';
import { postData } from '../services/api';
import { authLogin, authLogout } from '../store/authSlice';

const FrontLayout = ({ title = "" }) => {
  const dispatch = useDispatch()
  const page = window.location.pathname
  const pageParams = page.slice(1, 20)

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    const activeToken = localStorage.getItem('activeToken');

    const loadUser = async () => {
      const userData = await postData('verify-token', {}, token);
      if (userData.status === 200 && userData?.data?.active_token === activeToken) {
        dispatch(authLogin(userData.data));
      } else {
        dispatch(authLogout());
        localStorage.removeItem('accessToken');
        localStorage.removeItem('activeToken');
      }
    };

    if (token) {
      loadUser();
    }
  }, [dispatch, window.location.pathname]);

  return (
    <>
      <MetaTitle title={`Radisol | ${title}`} />
      <FrontHeader pageParams={pageParams} />
      <Outlet />
      {
        pageParams === 'course-details-quiz' ?
          <QuizDetailsFooter />
          :
          <FrontFooter />
      }
    </>
  );
};

export default FrontLayout;