import React from 'react';

const CourseGridItem = ({courseData}) => {
  return (
    <>
     {courseData.map((item) => (
      <div class="col-sm-6 col-xl-4" key={item._id}>
					<div class="card shadow h-100">
						
						<img src={item.courseImage} class="card-img-top" alt="course image" />
						<div class="card-body pb-0">
							
							<div class="d-flex justify-content-between mb-2">
								<a href="#" class="badge bg-success bg-opacity-10 text-success">{item.tag}</a>
								<a href="#" class="text-danger"><i class="fas fa-heart"></i></a>
							</div>
						
							<h5 class="card-title"><a href="course-detail.html">{item.name}</a></h5>
							<p class="mb-2 text-truncate-2">{item.description}</p>
						
							<ul class="list-inline mb-0">
								<li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
								<li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
								<li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
								<li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
								<li class="list-inline-item me-0 small"><i class="fas fa-star-half-alt text-warning"></i></li>
								<li class="list-inline-item ms-2 h6 fw-light mb-0">{item.rating}/5.0</li>
							</ul>
						</div>
						
						<div class="card-footer pt-0 pb-3">
							<hr/>
							<div class="d-flex justify-content-between">
								<span class="h6 fw-light mb-0"><i class="far fa-clock text-danger me-2"></i>{item.time}</span>
								<span class="h6 fw-light mb-0"><i class="fas fa-table text-orange me-2"></i>{item.lecture} lectures</span>
							</div>
						</div>
					</div>
				</div>
        ))}
    </>
  );
};

export default CourseGridItem;