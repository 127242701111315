import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import VideoDuration from '../../elements/VideoDuration';
import { managePlayPauseVideo } from '../../../store/courseSlice';
import { getPrevVideo } from '../../shared/course';

const CourseVideoCurriculumItem = ({
  video = '',
  videoItem = {},
  videoLength,
  index,
  curIndex,
  allVideos = [],
  allCurriculums = [],
  setVideo = () => { },
  setIsPassed = () => { },
  setPlaying = () => { }
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const videoId = new URLSearchParams(window.location.search).get('videoId')

  const user = useSelector(state => state.auth.user)
  const authCode = useSelector(state => state.auth.user?.role_details?.code)
  const activeLang = useSelector(state => state.lang.activeLang)
  const staticData = useSelector((state) => state.langActionStaticData.staticSiteData)
  const courseDetails = useSelector((state) => state.course.details.data);

  const quizable = getPrevVideo(videoItem, courseDetails).quizable
  const playable = getPrevVideo(videoItem, courseDetails).playable

  useEffect(() => {
    if (allVideos.length && videoId) {
      const video = allVideos.find(video => video._id === videoId)
      if (video) {
        setIsPassed(video.passed)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ allVideos.length, videoId ])

  useEffect(() => {
    if (videoId && (videoId === videoItem?._id) && playable) {
      setTimeout(() => {
        setVideo(null)
        setTimeout(() => {
          setPlaying(false)
          setVideo(new URL(videoItem.video).href)
        }, 1)
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoId, videoItem?._id, playable])

  return (
    <>
      <div className='d-sm-flex justify-content-sm-between align-items-center'>
        <div className='d-flex'>
          <a
            href='#!'
            className={`btn ${!playable ? 'btn-light' : 'btn-danger-soft'} btn-round mb-0 flex-shrink-0`}
            onClick={(e) => {
              e.preventDefault()

              if (playable) {
                if (!window.location.href.includes('course-video-player')) {
                  navigate(`/course-video-player/${courseDetails?._id}?videoId=${videoItem._id}`)
                } else {
                  if (
                    video && videoItem?.video &&
                    new URL(video).href === new URL(videoItem.video).href
                  ) {
                    dispatch(managePlayPauseVideo({
                      videoId: videoItem?._id,
                      playing: videoItem.playing ? false : true
                    }))
                  } else {
                    setVideo(null);
                    setPlaying(false)
                    setTimeout(() => setVideo(videoItem.video), 1);

                    dispatch(managePlayPauseVideo({
                      videoId: videoItem?._id,
                      playing: true
                    }))
                  }
                }
              }
            }}
          >
            {((video && videoItem.video && (new URL(video).href === new URL(videoItem.video).href)) && playable && videoItem.playing) ? (
              <i className='fas fa-pause me-0'></i>
            ) : playable ? (
              <i className='fas fa-play me-0'></i>
            ) : (
              <i className='bi bi-lock-fill'></i>
            )}
          </a>
          <div className='ms-2 ms-sm-3 mt-1 mt-sm-0'>
            <h6 className='mb-0'>
              {videoItem.name ? videoItem.name[activeLang] : ''}
            </h6>
            <p className='mb-2 mb-sm-0 small'>
              <VideoDuration ids={[videoItem._id]} />
            </p>
          </div>
        </div>

        {/* <!-- <a href="#" className="btn btn-sm btn-success mb-0">Play</a> --> */}
      </div>

      {(index !== (videoLength - 1) || quizable) && <hr />}

      {(quizable) && (
        (user?._id && (authCode !== 'user' && !user?.is_company_owner)) ? <></> :
        <>
          <div className='d-flex justify-content-sm-between align-items-center item-per-view'>
            <div className='d-flex align-items-center question-part'>
              <a
                href='#!'
                className='btn btn-danger-soft btn-round mb-0 flex-shrink-0'
                onClick={(e) => e.preventDefault()}
              >
                <i className='bi bi-lock-fill'></i>
              </a>
              <div className='ms-2 ms-sm-3 mt-1 mt-sm-0'>
                <h6 className='mb-0'>
                Eksam ({videoItem.name ? videoItem.name[activeLang] : ''})
                </h6>
              </div>
            </div>

            <Link
              to={`/course-details-quiz/${courseDetails?._id}/${videoItem._id}`}
              className='btn btn-sm btn-success mb-0 btn-attempt'
            >
              {staticData.attemptQuizBtn[activeLang]}
            </Link>
          </div>

          <hr />
        </>
      )}
    </>
  );
};

export default CourseVideoCurriculumItem;
