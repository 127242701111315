import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: {}
}

const siteSettingsSlice = createSlice({
    name: "testimonial",
    initialState,
    reducers: {
        siteSettingsData: (state, action) => {
            // state.siteLogo = action.payload
            state.data = action.payload 
        }
    }
})

export const { siteSettingsData } = siteSettingsSlice.actions
export default siteSettingsSlice.reducer