import { useSelector } from "react-redux";
import InstructorItem from "./InstructorItem";
import { useEffect, useState } from "react";

const CourseCurriculum = ({ curriculums = [] }) => {
  const activeLang = useSelector(state => state.lang.activeLang)
  const [toggle, setToggle] = useState(false)
  const [targetCollapse, setTargetCollapse] = useState([])

  useEffect(() => {
    if (curriculums.length > 0) {
      setTargetCollapse(curriculums.map((item, index) => ({
        value: true,
        index
      })))
    }
  }, [curriculums.length])

  const findAccordion = (i) => {
    const col = targetCollapse.find(item => item.index === i)
    return col ? col.index : null
  }

  return (
    <div
      className='tab-pane fade'
      id='course-pills-2'
      role='tabpanel'
      aria-labelledby='course-pills-tab-2'
    >
      <div
        className='accordion accordion-icon accordion-bg-light'
        id='accordionExample2'
      >

        {curriculums.map((item, i) => (
          <div className={`accordion-item ${targetCollapse[i]?.value ? 'active' : ''}`} key={i}>
            <h6 className='accordion-header font-base' id={`heading-1${i}`}>
              <button
                className='accordion-button fw-bold rounded d-sm-flex d-inline-block collapsed'
                type='button'
                data-bs-toggle='collapse'
                aria-expanded={i === findAccordion(i) ? (`${targetCollapse[i]?.value}`) : "false"}
                aria-controls={`collapseOne${i}`}
                targets={toggle}
                onClick={() => setTargetCollapse(data => {
                  const findCollapse = data.find(item => item.index === i)
                  if (!findCollapse) {
                    console.log("here1")
                    return [...data.map(item => ({ ...item, value: false })), { index: i, value: true }]
                  } else {
                    console.log("here2")
                    return data.map(item => item.index !== i ? {
                      ...item,
                      value: item.value
                    } : {
                      ...item,
                      value: !item.value
                    })
                  }
                })}
              >
                {item.name ? (item.name[activeLang] || '') : ''}
                <span className='d-block d-sm-inline-block small ms-sm-2'>
                  ({item.videos.length} {`Osa${item.videos.length === 1 ? '' : ''}`})
                </span>
              </button>
            </h6>
            <div
              id={`collapse-1${i}`}
              className={`accordion-collapse collapse ${targetCollapse[i]?.value ? 'show' : ''}`}
              aria-labelledby={`heading-1${i}`}
              data-bs-parent='#accordionExample2'
            >
              <div className='accordion-body mt-3'>
                {
                  item.videos.map(video => (
                    <InstructorItem key={video._id} video={video} />
                  ))
                }
                <hr />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CourseCurriculum;
