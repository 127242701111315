import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import CourseBanner from '../components/pagesections/CourseBanner';
import CourseOverview from '../components/pagesections/CourseOverview';
import { postData } from '../services/api';
import {
  getCourseDetailsRequested,
  getCourseDetails,
  getCourseDetailsFailed,
  emptyCourseDetails
} from '../store/courseSlice';
import MetaTitle from '../components/documenuts/MetaTitle';
import { useSelector } from 'react-redux';

const CourseDetails = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams();

  const activeLang = useSelector(state => state.lang.activeLang) || 'en'
  const courseDetails = useSelector(state => state.course.details.data)


  useEffect(() => {
    const loadCourseDetails = async () => {
      if (!id) return

      dispatch(getCourseDetailsRequested())
      const res = await postData('course/details', { id })
      if (res.status === 200) {
        if (res.data.coming_soon) {
          navigate('/')
        } else {
          dispatch(getCourseDetails(res.data))
        }
      } else {
        dispatch(getCourseDetailsFailed())
      }
    }

    loadCourseDetails()

    return () => {
      dispatch(emptyCourseDetails())
    }
  }, [id, dispatch])

  return (
    <>
      <MetaTitle 
        title={
          courseDetails?.title ? 
          `Radisol | ${courseDetails?.title[activeLang]}` : 'Radisol'
        } 
      />
      <CourseBanner />
      <CourseOverview />
    </>
  );
};

export default CourseDetails;
