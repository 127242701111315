import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import { setAnswer } from "../../../store/courseSlice";
// import { formatHHMMSS } from "../../../helpers";
import { toHHMM } from "../../../helpers/date";
import CourseQuizLoader from "../../Loader/CourseQuizLoader";

const CourseQuizSection = ({
  questions = [],
  updatedList = [],
  selectedQuestion = null,
  setSelectedQuestion = () => { },
  nextQuestion = () => { },
  onSubmit = () => { },
  seconds = null,
  lastAns,
  loading
}) => {
  const dispatch = useDispatch()
  const activeLang = useSelector(state => state.lang.activeLang)
  const staticData = useSelector((state) => state.langActionStaticData.staticSiteData)


  const findCheckedAnswer = (index) => {
    let answer

    questions.forEach((question, i) => {
      if (index === i) {
        question.answer.forEach((answerItem) => {
          if (answerItem.checked) {
            answer = answerItem
          }
        })
      }
    })

    return answer
  }


  return (
    <div className='col-12'>
      <div className='card shadow'>
        {
          !loading
            ?
            <div className='card-body'>
              <div className='card bg-transparent border rounded-3 mb-1'>
                <div className='card-header cStepper'>
                  <div className='cStepper-inner'>
                    {questions.map((item, index) => (
                      <div
                        className={
                          'cStepper-step' +
                          (item._id === selectedQuestion ? ' active' : '')
                        }
                        key={item._id}
                      >
                        <button
                          type='button'
                          className='cStepper-step-button'
                          onClick={() => {
                            const targetIndex = (index - 1) >= 0 ? (index - 1) : 0
                            if (!findCheckedAnswer(targetIndex)?.checked) {
                              toastr.error(
                                'Enne jätkamist palun valige vastus.'
                              )
                            } else {
                              setSelectedQuestion(item._id)
                            }
                          }}
                        >
                          <span className='cStepper-step-count'>{index + 1}</span>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <div className='card-body cStepper-content px-3 px-sm-4'>
                  <h6 className='text-danger text-center text-sm-end mb-3'>
                    <i className='bi bi-clock-history me-2'></i>{staticData.timeLeft[activeLang]} :&nbsp;
                    {
                      (typeof seconds === 'number' && seconds <= 0) ? staticData.timeOver[activeLang] :
                        ` ${toHHMM(seconds, true)}`
                    }
                  </h6>
                  <form>
                    {questions.map((item, index) => (
                      <div
                        className={
                          'content fade' +
                          (item._id === selectedQuestion ? ' active show' : '')
                        }
                        key={item._id}
                      >
                        <h5 className="lh-1 text-center text-sm-start">
                          {item.question ? item.question[activeLang] : ''}
                        </h5>
                        <hr />
                        <div className='vstack gap-2'>
                          {item.answer.map((answer) => (
                            <div key={answer._id}>
                              <input
                                type='radio'
                                className='btn-check'
                                checked={answer.checked || false}
                                name={'ques' + answer._id}
                                id={'option1' + answer._id}
                                onChange={() => dispatch(setAnswer({
                                  ansId: answer._id,
                                  qId: item._id
                                }))}
                              />
                              <label
                                className='btn btn-outline-primary w-100 answer-button'
                                htmlFor={'option1' + answer._id}
                              >
                                {answer.option ? answer.option[activeLang] : ''}
                              </label>
                            </div>
                          ))}
                        </div>
                        <div className='d-flex justify-content-center mt-3'>
                          <button
                            type='button'
                            className={`btn btn-primary next-btn mb-0 ${lastAns ? "disabled" : ""}`}
                            onClick={
                              (typeof seconds === 'number' && seconds <= 0) ?
                                () => window.location.reload() :
                                !findCheckedAnswer(index)?.checked ?
                                  () => toastr.error(
                                    'Enne jätkamist palun valige vastus.'
                                  ) :
                                  index === (questions.length - 1) ?
                                    () => onSubmit() :
                                    () => nextQuestion(index)
                            }
                          >
                            {
                              lastAns && 
                              <i className={'fa fa-spinner fa-spin'}></i>
                            }{lastAns && ' '}
                            {
                              (typeof seconds === 'number' && seconds <= 0) ? staticData.timeOverRetry[activeLang] :
                                index === (questions.length - 1) ?
                                  `Esita` :
                                  staticData.nextQsBtn[activeLang]
                            }
                          </button>
                        </div>
                      </div>
                    ))}
                  </form>
                </div>
              </div>
            </div>
            :
            <CourseQuizLoader />
        }
      </div>
    </div>
  );
};

export default CourseQuizSection;
