import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const CourseFAQ = ({ faqs = [] }) => {
  const activeLang = useSelector(state => state.lang.activeLang)
  const [toggle, setToggle] = useState(false)
  const [targetCollapse, setTargetCollapse] = useState([{index:0,value:true}])
  // const [targetCollapse, setTargetCollapse] = useState(0)

  useEffect(() => {
    if (faqs.length > 0) {
      setTargetCollapse(faqs.map((item, index) => ({
        value: true,
        index
      })))
    }
  }, [faqs.length])


  const findAccordion = (i) => {
    const col = targetCollapse.find(item => item.index === i)
    return col ? col.index : null
  }

  return (
    <div
      className='tab-pane fade'
      id='course-pills-5'
      role='tabpanel'
      aria-labelledby='course-pills-tab-5'
    >
      <h5 className='mb-3'>Korduma Kippuvad Küsimused</h5>


      <div className='accordion accordion-flush' id={`accordionExample`}>
        {faqs.map((faq, i) => (
          <div className={`accordion-item ${targetCollapse[i]?.value ? 'active' : ''}`} key={faq._id}>
            <h2 className='accordion-header' id={`headingOne${i}`}>
              <button
                className='accordion-button collapsed'
                type='button'
                data-bs-toggle='collapse'
                aria-expanded={i === findAccordion(i) ? (`${targetCollapse[i]?.value}`) : "false"}
                aria-controls={`collapseOne${i}`}
                // targets={toggle}
                onClick={() => setTargetCollapse(data => {
                  const findCollapse = data.find(item => item.index === i)
                  if (!findCollapse) {
                    return [...data.map(item => ({ ...item, value: false })), { index: i, value: true }]
                  } else {
                    return data.map(item => item.index !== i ? {
                      ...item,
                      value: item.value
                    } : {
                      ...item,
                      value: !item.value
                    })
                  }
                })}
              >
                <span className='text-secondary fw-bold me-3'>{i + 1}</span>
                <span className='h6 mb-0'>
                  {faq.question ? (faq.question[activeLang] || '') : ''}
                </span>
              </button>
            </h2>
            <div
              id={`collapseOne${i}`}
              className={`accordion-collapse collapse ${targetCollapse[i]?.value ? 'show' : ''}`}
              aria-labelledby={`headingOne${i}`}
              data-bs-parent={`#accordionExample${i}`}
            >
              <div className='accordion-body pt-0'
                dangerouslySetInnerHTML={{
                  __html: faq.answer ? (faq.answer[activeLang] || '') : ''
                }}
              >
              </div>
            </div>
          </div>
        ))}
      </div>

    </div>
  );
};

export default CourseFAQ;
