import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import { postData } from "../../services/api";
import { getCourseList, getCourseListFailed, getCourseListRequested } from "../../store/courseSlice";
import HeadingParagraphLoader from "../Loader/HeadingParagraphLoader";
import Details from "./ContactUs/Details";


const ContactUsForm = ({ headerSecData }) => {
  const dispatch = useDispatch();
  const activeLang = useSelector((state) => state.lang.activeLang)
  const staticData = useSelector((state) => state.langActionStaticData.staticSiteData)
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [course, setCourse] = useState('');
  const [participants, setParticipants] = useState(0);
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('')
  const [isUpdate, setIsUpdate] = useState(false);

  const [selectedOption, setSelectedOption] = useState({});
  const [optionsList, setOptionsList] = useState([]);

  const onSubmit = async (e) => {
    e.preventDefault()
    setIsUpdate(true);
    try {
      const userData = await postData("enquiry/add", {
        name: name,
        email: email,
        message: description,
        company,
        course,
        participants,
        type: "contact"
      });
      if (userData.status && userData.status === 200) {
        toastr.success(userData.message);
        setIsUpdate(false);
        setName('')
        setEmail('')
        setDescription('')
        setCompany('')
        setCourse('')
        setParticipants(0)
        setSelectedOption({})
      } else {
        toastr.error(userData.message);
        setIsUpdate(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsUpdate(false);
    }
  };

  useEffect(() => {
    const loadCourses = async () => {
      dispatch(getCourseListRequested())
      const res = await postData('course/list', {})
      if (res.status === 200) {
        setOptionsList(res.data);
        dispatch(getCourseList(res.data))
      } else {
        dispatch(getCourseListFailed())
      }
    }
    loadCourses();
  }, [dispatch]);

  return (
    <section className="contact-form-section">
      <div className="container">
        <div className="row g-4 g-lg-0 justify-content-between">
          <div className="col-md-5 align-items-center text-center">
            <div className="contact-information">
              <div className="customer-support">
                {
                  headerSecData?.map((item) =>
                    <Details key={item._id} title={item.title} location={item.location} phone={item.phone} email={item.email} backgroundColor={item.backgroundColor} color={item.color}
                    />
                  )
                }
              </div>
            </div>
          </div>

          <div className="col-md-6">
            {headerSecData.length <= 0 || headerSecData[0].title === undefined ? (
              <HeadingParagraphLoader />
            ) : (
              <>
                <h2 className="mt-4 mt-md-0">{staticData.contactPage.title[activeLang]}</h2>
                <p>{staticData.contactPage.subTitle[activeLang]}</p>
              </>
            )}

            <form>
              <div className="mb-4 bg-light-input">
                <label htmlFor="yourName" className="form-label">
                  {staticData.contactPage.formData.name[activeLang]}*
                </label>
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control form-control-lg" id="yourName" />
              </div>

              <div className="mb-4 bg-light-input">
                <label htmlFor="yourName" className="form-label">
                  {staticData.contactPage.formData.companyName[activeLang]}{participants > 0 ? "*" : ""}
                </label>
                <input type="text" value={company} onChange={(e) => setCompany(e.target.value)} className="form-control form-control-lg" id="yourName" />
              </div>
              <div className="mb-4 bg-light-input">
                <label htmlFor="yourName" className="form-label">
                  {staticData.contactPage.formData.course[activeLang]}
                </label>

                <select className="form-select" aria-label="Default select example"
                  onClick={(e) => {
                    setCourse(e.target.value);
                  }}
                >
                  <option value={""}>Vali kursus</option>
                  {optionsList.map((option) => (
                    <option
                      key={option?._id}
                      className="dropdown-item"
                      value={option?._id}
                    >
                      {option?.title[activeLang]}
                    </option>
                  ))}
                </select>

              </div>
              <div className="mb-4 bg-light-input">
                <label htmlFor="yourName" className="form-label">
                  {staticData.contactPage.formData.participantsCount[activeLang]}{company ? "*" : ""}
                </label>
                <input type="text" value={participants.toString()} onChange={(e) => {
                  if (/^\d{1,4}$/.test(e.target.value) || e.target.value === "") {
                    setParticipants(Number(e.target.value) ?? 0);
                  } else {
                    return;
                  }
                }} className="form-control form-control-lg" id="yourName" />
              </div>

              <div className="mb-4 bg-light-input">
                <label htmlFor="emailInput" className="form-label">
                  {staticData.contactPage.formData.email[activeLang]}*
                </label>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control form-control-lg" id="emailInput" />
              </div>
              <div className="mb-4 bg-light-input">
                <label htmlFor="textareaBox" className="form-label">
                  {staticData.contactPage.formData.message[activeLang]}*
                </label>
                <textarea className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} id="textareaBox" rows="4"></textarea>
              </div>
              <div className="d-grid">
                <button
                  className="btn btn-lg btn-primary mb-0" type="button"
                  disabled={isUpdate}
                  onClick={onSubmit}>
                  <i className={isUpdate ? 'fa fa-spinner fa-spin' : 'fa fa-check'}></i> {staticData.contactPage.formData.sndBtn[activeLang]}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUsForm;
