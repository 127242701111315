import React from "react";

const CourseLanguageFilter = () => {
  return (
    <>
      <div className="card card-body shadow p-4 mb-4">
        <h4 className="mb-3">Language</h4>
        <ul className="list-inline mb-0 g-3">
          <li className="list-inline-item mb-2">
            <input type="checkbox" className="btn-check" id="btn-check-2" />
            <label className="btn btn-light btn-primary-soft-check" for="btn-check-2">
              English
            </label>
          </li>
          <li className="list-inline-item mb-2">
            <input type="checkbox" className="btn-check" id="btn-check-3" />
            <label className="btn btn-light btn-primary-soft-check" for="btn-check-3">
              Francas
            </label>
          </li>
          <li className="list-inline-item mb-2">
            <input type="checkbox" className="btn-check" id="btn-check-4" />
            <label className="btn btn-light btn-primary-soft-check" for="btn-check-4">
              Hindi
            </label>
          </li>
          <li className="list-inline-item mb-2">
            <input type="checkbox" className="btn-check" id="btn-check-5" />
            <label className="btn btn-light btn-primary-soft-check" for="btn-check-5">
              Russian
            </label>
          </li>
          <li className="list-inline-item mb-2">
            <input type="checkbox" className="btn-check" id="btn-check-6" />
            <label className="btn btn-light btn-primary-soft-check" for="btn-check-6">
              Spanish
            </label>
          </li>
          <li className="list-inline-item mb-2">
            <input type="checkbox" className="btn-check" id="btn-check-7" />
            <label className="btn btn-light btn-primary-soft-check" for="btn-check-7">
              Bengali
            </label>
          </li>
          <li className="list-inline-item mb-2">
            <input type="checkbox" className="btn-check" id="btn-check-8" />
            <label className="btn btn-light btn-primary-soft-check" for="btn-check-8">
              Portuguese
            </label>
          </li>
        </ul>
      </div>
    </>
  );
};

export default CourseLanguageFilter;
