import CourseVideoCurriculumSections from "./CourseVideoPlayerSection/CourseVideoCurriculumSections";

const CourseSideBar = () => {
  return (
    <>
      <div data-sticky data-margin-top="80" data-sticky-for="768">
        <div className="row g-4">

          <div className="col-12">
            <div className="card shadow rounded-3">
              <div className="card-header border-bottom">
                <h3 className="mb-0">Õppekava</h3>
              </div>
              
              <CourseVideoCurriculumSections />
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default CourseSideBar;