import { useSelector } from 'react-redux';
import CourseVideoCurriculumItem from './CourseVideoCurriculumItem';

const CourseVideoCurriculumSections = ({ activeVideo, setVideo, setIsPassed, setPlaying }) => {
  const activeLang = useSelector(state => state.lang.activeLang)
  const courseDetails = useSelector((state) => state.course.details.data);

  return (
    <div className='card-body course-list-view'>
      <div className='row gap-5'>
        {courseDetails?.curriculums &&
          courseDetails.curriculums.map((item, curI, allCurs) => (
            <div className='col-12' key={item._id}>
              <h5 className='mb-4'>
                {item.name ? item.name[activeLang] : ''}
              </h5>

              {item.videos.map((video, i, allVideos) => (
                <CourseVideoCurriculumItem
                  key={video._id}
                  index={i}
                  curIndex={curI}
                  videoItem={video}
                  allVideos={allVideos}
                  allCurriculums={allCurs}
                  videoLength={item.videos.length}
                  video={activeVideo}
                  setVideo={setVideo}
                  setIsPassed={setIsPassed}
                  setPlaying={setPlaying}
                />
              ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default CourseVideoCurriculumSections;
