import React from 'react';
import BgCallToAction from '../../assets/images/bg-calltoaction.webp'
import Image from "../elements/Image";
import { Link } from "react-router-dom";
import { initializeUseSelector, useSelector } from 'react-redux/es/hooks/useSelector';

const CallToAction = () => {
  const activeLang = useSelector((state) => state.lang.activeLang)
  const ctaData = useSelector((state) => state.pageData?.callToActionData?.howToOrder);

  return (
    <>
      <section className="sec-calltoaction">
        <div className="background-image">
          <Image
            src={ctaData?.image || BgCallToAction}
            alt={""}
            width={"100%"}
            height={"100%"}
            effect={"blur"}
            classes={""}
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-9 col-md-7 col-lg-6 col-xl-5">
              <div className="calltoaction-content">
                {/* <h2 className="title">Muuda oma kiirgustegevuse haldamine automaatseks</h2> */}
                <h2 className="title">{ctaData?.name[activeLang]}</h2>
                {/* <div className="description">
                  <p>Radisoli kiirgustarkvara on loodud ühilduma teie seadmetega, koondades kõik kutsekiirituse dooside andmed ühte kohta. See tagab täpse ja automaatse ülevaate kõigist teie kliiniku kiirgustegevustest. Lisaks teavitab Radisoli tarkvara automaatselt, millal on vajalik teha seadmete kontrollmõõdistusi või korraldada töötajatele kiirgusohutuse koolitusi, aidates tagada nii seadmete optimaalse töökorra, töötajate ohutuse, kui ka lihtsaima viisi Keskkonnaametile aruannte esitamiseks.</p>
                </div>ctaData?.description[activeLang] */}
                <div className="description">
                  <p dangerouslySetInnerHTML={{ __html: ctaData?.description[activeLang] }} />
                </div>
                <div className="actions">
                  <Link to={"https://radisol.ee/"} target='_blank' className="btn btn-primary-soft btn-action">Tutvu lahendusega</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CallToAction;