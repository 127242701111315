import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";


const GetInTouch = () => {
    const siteData = useSelector(state => state.siteSettings.data)
    const activeLang = useSelector((state) => state.lang.activeLang)
    const staticData = useSelector((state) => state.langActionStaticData.staticSiteData)

    return (
        <div className="d-flex align-items-center gap-4 mt-4">
            <Link to={"/contact-us"} className="btn btn-primary-soft mb-0 btn-order">
                <span className='d-flex'>
                    <span><i className="fas fa-envelope me-1"></i></span>
                    {staticData.getInTouchBtn[activeLang]}
                </span>
            </Link>

            {/* <a href="tel:+37255515010" className="m-0 call-action">
                <span><i className="bi bi-telephone-fill"></i></span>
                <div className="text">{siteData?.contact_address?.contactPhone}</div>
            </a> */}
        </div>
    )
}

export default GetInTouch