import React from 'react'

const TermsLoader = () => {
    return (
        <div>
            <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-75 rounded'></div>

            <div className='text-loader fadeoutAnimate bg-light loader-h-25 w-25 my-4 rounded'></div>

            <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-25 mt-1 rounded'></div>
            <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-75 mt-1 rounded'></div>
            <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-50 mt-1 rounded'></div>
            <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-25 mt-1 rounded'></div>
            <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-25 mt-1 rounded'></div>

            <div className='text-loader fadeoutAnimate bg-light loader-h-25 w-25 my-4 rounded'></div>

            <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-25 mt-1 rounded'></div>
            <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-75 mt-1 rounded'></div>
            <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-50 mt-1 rounded'></div>
            <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-25 mt-1 rounded'></div>
            <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-25 mt-1 rounded'></div>
            <div className='text-loader fadeoutAnimate bg-light loader-h-25 w-25 my-4 rounded'></div>

            <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-25 mt-1 rounded'></div>
            <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-75 mt-1 rounded'></div>
            <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-50 mt-1 rounded'></div>
            <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-25 mt-1 rounded'></div>
            <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-25 mt-1 rounded'></div>
        </div>
    )
}

export default TermsLoader