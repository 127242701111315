import { useEffect } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import FrontLayout from './layouts/FrontLayout';
import { useDispatch, useSelector } from 'react-redux';
import FreeAuthRoute from './routes/FreeAuthRoute';
import ProtectedRoute from './routes/ProtectedRoute';
import Home from './pages/Home';
import CourseList from './pages/CourseList';
import EditProfile from './pages/EditProfile';
import Dashboard from './pages/Dashboard';
import ContactUs from './pages/ContactUs';
// import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import CourseDetails from './pages/CourseDetails';
import AdmissionForm from './pages/AdmissionForm';
import CourseDetailsAdv from './pages/CourseDetailsAdv';
import CourseDetailsQuiz from './pages/CourseDetailsQuiz';
// import AboutUs from './pages/AboutUs';
import VideoDetails from './pages/VideoDetails';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';

import Verify from './pages/Verify';
import { postData } from './services/api';
import { authLogin, authLogout } from './store/authSlice';
import {
  langRequested,
  langResponse,
  langError,
  getActiveLang,
} from './store/langSlice';
import UserDashboard from './pages/UserDashboard';
import ViewPdf from './pages/ViewPdf';
import PageNotFound from './pages/PageNotFound';

const CreateRoutes = () => {
  const dispatch = useDispatch();
  const langs = useSelector((state) => state.lang.list.data);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    const activeToken = localStorage.getItem('activeToken');

    const loadUser = async () => {
      const userData = await postData('verify-token', {}, token);
      if (userData.status === 200 && userData?.data?.active_token === activeToken) {
        dispatch(authLogin(userData.data));
      } else {
        dispatch(authLogout());
        localStorage.removeItem('accessToken');
        localStorage.removeItem('activeToken');
      }
    };

    if (token) {
      loadUser();
    }
  }, [dispatch]);

  useEffect(() => {
    if (!langs.length) {
      const loadLangs = async () => {
        dispatch(langRequested());
        const res = await postData('lang/list', {});
        if (res.status === 200) {
          dispatch(langResponse(res.data));

          const findLocalLang = res.data.find(
            (item) => item.code === localStorage.getItem('lang')
          );

          if (!findLocalLang) {
            const defaultLang = res.data.find((item) => item.default);
            if (defaultLang) {
              dispatch(getActiveLang(defaultLang.code));
              localStorage.setItem('lang', defaultLang.code);
            }
          }
        } else {
          dispatch(langError());
        }
      };

      loadLangs();
    }
  }, [dispatch, langs.length]);

  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path='/' element={<FrontLayout title={'Home'} />}>
            <Route path='/' exact element={<Home />} />
          </Route>
          {/* <Route path='/sign-up'>
            <Route
              path='/sign-up'
              exact
              element={
                <FreeAuthRoute>
                  <SignUp />
                </FreeAuthRoute>
              }
            />
          </Route> */}
          <Route path='/sign-in'>
            <Route
              path='/sign-in'
              exact
              element={
                <FreeAuthRoute title={'Sign In'}>
                  <SignIn />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path='/verify'>
            <Route
              path='/verify'
              exact
              element={
                <FreeAuthRoute title={'Verify'}>
                  <Verify />
                </FreeAuthRoute>
              }
            />
          </Route>


          <Route path='/course-list' element={<FrontLayout title={'Course List'} />} >
            <Route path='/course-list' exact element={<CourseList />} />
          </Route>


          <Route path='/edit-profile' element={<FrontLayout title='Edit Profile' />}>
            <Route path='/edit-profile' exact element={
              <ProtectedRoute>
                <EditProfile />
              </ProtectedRoute>
            } />
          </Route>


          <Route
            path='/my-course'
            element={<FrontLayout title={'My Courses'} />}
          >
            <Route
              path='/my-course'
              exact
              element={
                <ProtectedRoute firstLoad={true}>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path='/manage-users'
            element={<FrontLayout title={'Manage Users'} />}
          >
            <Route
              path='/manage-users'
              exact
              element={
                <ProtectedRoute firstLoad={true}>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/user/:id' element={<FrontLayout title="Dashboard" />}>
            <Route
              path='/user/:id'
              element={
                <ProtectedRoute firstLoad={true}>
                  <UserDashboard />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/contact-us' element={<FrontLayout title='Contact Us' />}>
            <Route path='/contact-us' exact element={<ContactUs />} />
          </Route>
          <Route path='/terms' element={<FrontLayout title='Terms and Conditions' />}>
            <Route path='/terms' exact element={<Terms />} />
          </Route>
          <Route path='/privacy' element={<FrontLayout title='Privacy Policy' />}>
            <Route path='/privacy' exact element={<Privacy />} />
          </Route>
          <Route path='/course-details/:id' element={<FrontLayout title='Course' />}>
            <Route
              path='/course-details/:id'
              element={
                <FreeAuthRoute>
                  <CourseDetails />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path='/course-video-player/:id'>
            <Route
              path='/course-video-player/:id'
              element={
                <ProtectedRoute title="Video Player">
                  <VideoDetails />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/course-details-adv/:id' element={<FrontLayout title='Course' />}>
            <Route
              path='/course-details-adv/:id'
              exact
              element={
                <ProtectedRoute firstLoad={true}>
                  <CourseDetailsAdv />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path='/course-details-quiz/:id/:curId'
            element={<FrontLayout title='Course' />}
          >
            <Route
              path='/course-details-quiz/:id/:curId'
              element={
                <ProtectedRoute firstLoad={true}>
                  <CourseDetailsQuiz />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/admission-form' element={<FrontLayout title='Course' />}>
            <Route path='/admission-form' exact element={<AdmissionForm />} />
          </Route>
          <Route path='/view-pdf' element={<FrontLayout title='Course' />}>
            <Route
              path='/view-pdf'
              exact
              element={
                <ProtectedRoute>
                  <ViewPdf />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path='*' element={<FrontLayout title={'404'} />}>
            <Route path='*' element={<PageNotFound />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default CreateRoutes;
