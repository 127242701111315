import React from 'react'
import UserTableLoaderItem from "./UserTableLoaderItem"

const UserTableLoader = () => {
  return (
    <>
      <div className="userlist">
        <UserTableLoaderItem/>
        <UserTableLoaderItem/>
        <UserTableLoaderItem/>
        <UserTableLoaderItem/>
        <UserTableLoaderItem/>
      </div>
    </>
  )
}

export default UserTableLoader