import React from 'react';

const UserTableLoaderItem = () => {
  return (
    <>
      <div className="userlist-item">
        <div className="userlist-item-info">
          <div className="userlist-name"><div className='text-loader fadeoutAnimate loader-bg-light loader-h-25 col-9 col-sm-6 rounded'></div></div>
          <div className="userlist-meta">
            <div className="userlist-meta-item">
              <div className="userlist-meta-label"><div className='text-loader fadeoutAnimate loader-bg-light loader-h-15 col-12 rounded'></div></div>
              <div className="userlist-meta-data"><div className='text-loader fadeoutAnimate loader-bg-light loader-h-15 col-12 col-sm-8 rounded'></div></div>
            </div>
            <div className="userlist-meta-item">
              <div className="userlist-meta-label"><div className='text-loader fadeoutAnimate loader-bg-light loader-h-15 col-12 rounded'></div></div>
              <div className="userlist-meta-data"><div className='text-loader fadeoutAnimate loader-bg-light loader-h-15 col-12 col-sm-8 rounded'></div></div>
            </div>
            <div className="userlist-meta-item">
              <div className="userlist-meta-label"><div className='text-loader fadeoutAnimate loader-bg-light loader-h-15 col-12 rounded'></div></div>
              <div className="userlist-meta-data"><div className='text-loader fadeoutAnimate loader-bg-light loader-h-15 col-12 col-sm-8 rounded'></div></div>
            </div>
          </div>
        </div>
        <div className="userlist-item-options">
          <div className='text-loader fadeoutAnimate loader-bg-light loader-h-35 w-100 rounded'></div>
          <div className='text-loader fadeoutAnimate loader-bg-light loader-h-35 w-100 rounded'></div>
        </div>
      </div>
    </>
  );
};

export default UserTableLoaderItem;