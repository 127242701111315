import { useState, useEffect } from 'react'
import { toHHMMSS } from '../../helpers/date'
import { postData } from '../../services/api'

const VideoDuration = ({ ids = [] }) => {
  const [duration, setDuration] = useState(0)

  useEffect(() => {
    const getVideoDuration = async () => {
      if (!ids.length) return

      const res = await postData('video-duration/get', {
        videoIds: ids
      })

      if (res.status === 200) {
        setDuration(res.data)
      }
    }

    getVideoDuration()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ ids.length ])

  return toHHMMSS(`${Math.round(duration)}`, duration <= 60)
}

export default VideoDuration