import React from "react";

const CoursePriceFilter = () => {
  return (
    <>
      <div className="card card-body shadow p-4 mb-4">
        <h4 className="mb-3">Price Level</h4>
        <ul className="list-inline mb-0">
          <li className="list-inline-item">
            <input type="radio" className="btn-check" name="options" id="option1" />
            <label className="btn btn-light btn-primary-soft-check" for="option1">
              All
            </label>
          </li>
          <li className="list-inline-item">
            <input type="radio" className="btn-check" name="options" id="option2" />
            <label className="btn btn-light btn-primary-soft-check" for="option2">
              Free
            </label>
          </li>
          <li className="list-inline-item">
            <input type="radio" className="btn-check" name="options" id="option3" />
            <label className="btn btn-light btn-primary-soft-check" for="option3">
              Paid
            </label>
          </li>
        </ul>
      </div>
    </>
  );
};

export default CoursePriceFilter;
