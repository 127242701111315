import React from 'react'

const CourseQuizLoader = () => {
    return (
        <div className='quiz-box-loader text-loader'>
            <div className='p-3 w-100'>
                <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-35 w-50 mb-5 rounded'></div>
                <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-15 w-75 mb-1 rounded'></div>
                <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-15 w-25 mb-1 rounded'></div>
                <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-15 w-50 mb-1 rounded'></div>
                <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-15 w-75 mb-1 rounded'></div>

                <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-35 w-50 my-3 rounded'></div>
                <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-15 w-75 mb-1 rounded'></div>
                <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-15 w-25 mb-1 rounded'></div>
                <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-15 w-50 mb-1 rounded'></div>
                <div className='text-loader fadeoutAnimate loader-bg-teal-light loader-h-15 w-75 mb-1 rounded'></div>
            </div>
        </div>
    )
}

export default CourseQuizLoader