import React from 'react';
import Image from '../components/elements/Image';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const FrontFooter = () => {
  const siteData = useSelector(state => state.siteSettings.data)

  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-md-flex justify-content-between align-items-center pt-2 pb-4 text-center">
                <div className="text-primary-hover">{siteData.copyright}</div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FrontFooter;