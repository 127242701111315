import React from 'react';
import { useNavigate } from "react-router-dom";
import CourseTableHead from './Dashboard/CourseTableHead';
import EditProfileSideMenu from './EditProfileSideMenu';
import CourseTable from './Dashboard/CourseTable';
import { useSelector } from 'react-redux';
import { NO_IMAGE } from '../../config/host';
import CourseTableLoader from '../Loader/CourseTableLoader';
import NoResult from '../elements/NoResult';
import { Button } from "bootstrap";

const UserDashboardBody = ({ profileSideMenu }) => {
  const activeLang = useSelector((state) => state.lang.activeLang)
  const staticData = useSelector((state) => state.langActionStaticData.staticSiteData)
  const courseListOfUser = useSelector((state) => state?.userDataList.userData);
  const loading = useSelector((state) => state?.userDataList.loading);
  const navigate = useNavigate();


  return (
    <section className='pt-0 bg-light section-min-height'>
      <div className='container'>
        <div className='row'>
          <EditProfileSideMenu menu={profileSideMenu} />

          <div className='col-xl-9'>
            <div className='card bg-white border rounded-3'>
              <div className='card-header bg-white border-bottom d-flex gap-3'>
                <button type="button" className="btn-back" onClick={() => navigate(-1)}><i className="fas fa-fw fa-arrow-left"></i></button>
                <h3 className='mb-0'>Minu kasutajate kursuste nimekiri</h3>
              </div>

              <div className='card-body'>
                {loading ? <CourseTableLoader /> : (
                  <div className="courseslist">
                    {courseListOfUser?.length > 0 ?
                      (courseListOfUser?.map((list) => (
                        <CourseTable
                          key={list._id}
                          course={list}
                          image={list.image || NO_IMAGE}
                          courseName={list.title[activeLang]}
                          link={list._id}
                          id={list._id}
                          percentage={list.percentage}
                          totalLectures={list.total_lectures}
                          completedLectures={list.completed_lectures}
                        />
                      ))
                      ) : (
                        <NoResult />
                      )
                    }
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserDashboardBody;
