import React,{useState,useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { postData } from '../services/api';
import { authLogin, authLogout } from '../store/authSlice';
import toastr from 'toastr';

const Verify = () => {
  //const {hash_number } = useParams();
  const hashNumber = new URLSearchParams(window.location.search).get("hash");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false);
  
  useEffect(() => {
      async function verifyData() {
        setLoading(true);
        try {
          const result = await postData("verify-hash", {
            hash_number:hashNumber
          });
          if (result.status && result.status === 200) {
            setLoading(true);
            dispatch(authLogin(result.data));
            localStorage.setItem("accessToken", result?.accessToken);
            localStorage.setItem("role", result?.data?.code);
            localStorage.setItem("activeToken", result?.activeToken)
            toastr.success(result.message);
            navigate("/my-course");
          } else {
            localStorage.removeItem('accessToken')
            localStorage.removeItem('activeToken');
            dispatch(authLogout())
            setLoading(false);
            toastr.error(result.message);

            navigate("/sign-in");
          }
        } catch (error) {
          setLoading(false);
          toastr.error(error.message);
        }
      }
      verifyData();        
  }, []);

  return (
    <div>
      please wait....
    </div>
  );
};

export default Verify;