import { useSelector } from 'react-redux';
import CourseItem from '../items/CourseItem';
import CourseListLoader from '../Loader/CourseListLoader';
import NoCourseAvailable from '../elements/NoCourseAvailable';

const Courses = () => {
  const loading = useSelector(state => state.course.list.loading)
  const courseData = useSelector(state => state.course.list.data)
  const activeLang = useSelector((state) => state.lang.activeLang)
  const staticData = useSelector((state) => state.langActionStaticData.staticSiteData)

  return (
    <section>
      <div className='container'>
        <div className='row mb-4'>
          <div className='col-lg-8 mx-auto text-center'>
            <h2 className='fs-1'>{staticData.courseHeading[activeLang]}</h2>
          </div>
        </div>
        {
          loading
            ?
            <CourseListLoader />
            :
            courseData?.length > 0
              ?
              < div className='row g-4'>
                {courseData.map((item) => (
                  <CourseItem key={item._id} item={item} />
                ))}
              </div>
              :
              <NoCourseAvailable />
        }
      </div>
    </section >
  );
};

export default Courses;
