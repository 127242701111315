import React from 'react'

const HeadingParagraphLoader = () => {
  return (
    <div>
        <div className='text-loader fadeoutAnimate bg-light loader-h-35 w-50 mb-2 rounded'></div>
        <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-75 mb-1 rounded'></div>
        <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-75 mb-1 rounded'></div>
        <div className='text-loader fadeoutAnimate bg-light loader-h-15 w-50 mb-1 rounded'></div>
    </div>
  )
}

export default HeadingParagraphLoader