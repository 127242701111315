import { useSelector } from "react-redux";
import { postData } from "../../services/api";
import toastr from "toastr";
import { useRef, useState } from "react";


const AddUserModal = ({ children, userData, title, setModalClose, functions, editUserId, list, setMyUserList, addType = "ASC" }) => {
    const auth = useSelector((state) => state?.auth.user)
    const [loading, setLoading] = useState(false)

    const closeModalBtn = useRef()



    const onSubmit = async () => {
        setLoading(true)
        const res = await postData(`user/${editUserId ? "edit" : "add"}`, {
            ...userData,
            type: "user",
            company_id: auth?._id,
            id: editUserId || null,
            ordering: editUserId ? editUserId.ordering : list.length,
        });

        if (res.status === 200) {
            setModalClose(false)
            closeModalBtn.current.click()
            setLoading(false)
            if (!editUserId) {
                if (addType !== "DESC") {
                    setMyUserList((list) => ({
                        ...list,
                        data: [...list.data, res.data],
                        count: list.count + 1,
                    }));
                } else {
                    setMyUserList((list) => ({
                        ...list,
                        data: [res.data, ...list.data],
                        count: list.count + 1,
                    }));
                }
            } else if (userData && editUserId) {
                setMyUserList((list) => ({
                    ...list,
                    data: list.data.map((item) =>
                        item._id !== editUserId ? item : res.data
                    ),
                }));
            }

            toastr.success(res.message);
        } else {
            setLoading(false);
            toastr.error(res.message);
        }
    }

    return (
        <>
            <div
                className="modal fade"
                id="staticBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered user-modal-size">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">{title}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={functions}></button>
                        </div>
                        <div className="modal-body">
                            {/* this is a child component which is a from */}
                            {children}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" ref={closeModalBtn} onClick={functions}>Sulge</button>
                            <button type="button" className="btn btn-success" onClick={onSubmit} disabled={loading}>Kinnita</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddUserModal