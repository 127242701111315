import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { authLogout } from '../../store/authSlice'

const EditProfileSideMenu = ({ menu }) => {
    return (
        <>
            <div className="col-xl-3">
                <div className="offcanvas-xl offcanvas-end" id="offcanvasSidebar" aria-labelledby="offcanvasSidebarLabel">
                    <div className="offcanvas-header bg-light">
                        <h5 className="offcanvas-title" id="offcanvasNavbarLabel">My profile</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body p-3 p-xl-0">
                        <div className=" bg-blue border rounded-3 pb-0 p-3 w-100">
                            <div className="list-group list-group-dark list-group-borderless">
                                {
                                    menu?.length > 0 &&
                                    menu.map((list) =>
                                        <NavLink key={list._id} to={list.link} className="list-group-item"
                                            onClick={list.onClick ? (e) => list.onClick(e) : (() => {})}
                                        >
                                            <i className={`bi ${list.icon} fa-fw me-2`}></i>{list.name}
                                        </NavLink>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditProfileSideMenu