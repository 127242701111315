import React, { useState } from "react";
import SectionBanner from "../components/elements/SectionBanner";
import CourseGridItem from "../components/items/CourseGridItem";
import CourseListItem from "../components/items/CourseListItem";
import CourseCategoryFilter from "../components/pagesections/CourseCategoryFilter";
import CourseLanguageFilter from "../components/pagesections/CourseLanguageFilter";
import CoursePriceFilter from "../components/pagesections/CoursePriceFilter";
import MetaTitle from "../components/documenuts/MetaTitle";

const CourseList = () => { 

  const [toggle, setToggle]= useState(true)

  const courseData = [
    {
      _id: 1,
      type: "Free",
      tag: "Development",
      rating: "4.5",
      courseImage:'assets/images/courses/4by3/06.jpg',
      name: "Angular – The Complete Guider",
      description: "Satisfied conveying a dependent contented he gentleman agreeable do be. dependent contented he",
      time: "21h 56m",
      lecture: "52",
      level: "Intermediate",
      userImage: "assets/images/avatar/06.jpg",
      authorName: "Jacqueline Miller",
    },
    {
      _id: 2,
      type: "",
      tag: "Development",
      rating: "4.5",
      courseImage:'assets/images/courses/4by3/03.jpg',
      name: "Angular – The Complete Guider",
      description: "Satisfied conveying a dependent contented he gentleman agreeable do be. dependent contented he",
      time: "21h 56m",
      lecture: "52",
      level: "Intermediate",
      userImage: "assets/images/avatar/06.jpg",
      authorName: "Jacqueline Miller",
    },
    {
      _id: 3,
      type: "",
      tag: "Development",
      rating: "4.5",
      courseImage:'assets/images/courses/4by3/11.jpg',
      name: "The Ultimate Copywriting Course - Write Copy That Sells",
      description: "Insipidity the sufficient discretion imprudence resolution sir him decisively. Proceed how any engaged visitor.",
      time: "21h 56m",
      lecture: "52",
      level: "Intermediate",
      userImage: "assets/images/avatar/06.jpg",
      authorName: "Jacqueline Miller",
    },
    {
      _id: 2,
      type: "",
      tag: "Development",
      rating: "4.5",
      courseImage:'assets/images/courses/4by3/11.jpg',
      name: "Angular – The Complete Guider",
      description: "Satisfied conveying a dependent contented he gentleman agreeable do be. dependent contented he",
      time: "21h 56m",
      lecture: "52",
      level: "Intermediate",
      userImage: "assets/images/avatar/06.jpg",
      authorName: "Jacqueline Miller",
    },
  ];

  return (
    <>
      <SectionBanner />
      <section className="pb-0 py-sm-5">
        <div className="container">
          <div className="row g-3 align-items-center mb-4">
            <div className="col-md-9">
              <h4 className="mb-0">Showing 1-7 of 32 result</h4>
            </div>

            <div className="col-md-3">
              <div className="row g-3 align-items-center justify-content-end">
                <div className="col-sm-4 col-xl-6 text-md-end d-none d-md-block">
                  <ul className="list-inline mb-0">

                    <li className="list-inline-item" onClick={()=> setToggle(false)}>
                      <div className={"btn mb-0 me-2" + (toggle?" btn-outline-light":" btn-primary")}>
                        <i className="fas fa-fw fa-th-large"></i>
                      </div>
                    </li>

                    <li className="list-inline-item" onClick={()=> setToggle(true)}>
                      <div className={"btn mb-0 me-2" + (!toggle?" btn-outline-light":" btn-primary")}>
                        <i className="fas fa-fw fa-list-ul"></i>
                      </div>
                    </li>
                  </ul>
                </div>

                {/* <div className="col-4 text-md-end">
                  <button className="btn btn-primary mb-0 d-xl-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSidebar" aria-controls="offcanvasSidebar">
                    <i className="fas fa-sliders-h me-1"></i> Show filter
                  </button>
                </div> */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-9 col-xxl-9">
              <div className="row g-4">
              {
                toggle?
                <CourseListItem courseData={courseData}/>
                :
                <CourseGridItem courseData={courseData}/> 
              }
              </div>

              <div className="col-12">
                <nav className="mt-4 d-flex justify-content-center" aria-label="navigation">
                  <ul className="pagination pagination-primary-soft d-inline-block d-md-flex rounded mb-0">
                    <li className="page-item mb-0">
                      <a className="page-link" href="#" tabindex="-1">
                        <i className="fas fa-angle-double-left"></i>
                      </a>
                    </li>
                    <li className="page-item mb-0">
                      <a className="page-link" href="#">
                        1
                      </a>
                    </li>
                    <li className="page-item mb-0 active">
                      <a className="page-link" href="#">
                        2
                      </a>
                    </li>
                    <li className="page-item mb-0">
                      <a className="page-link" href="#">
                        ..
                      </a>
                    </li>
                    <li className="page-item mb-0">
                      <a className="page-link" href="#">
                        6
                      </a>
                    </li>
                    <li className="page-item mb-0">
                      <a className="page-link" href="#">
                        <i className="fas fa-angle-double-right"></i>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>

            <div className="col-lg-3 col-xxl-3">
              <div className="offcanvas-xl offcanvas-end" tabindex="-1" id="offcanvasSidebar" aria-labelledby="offcanvasSidebarLabel">
                <div className="offcanvas-header bg-light">
                  <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                    Advance Filter
                  </h5>
                  <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-3 p-xl-0">
                  <form>
                    <CourseCategoryFilter/>
                    <CoursePriceFilter/>
                    <CourseLanguageFilter/>
                  </form>
                </div>
                <div className="d-grid p-2 p-xl-0 bg-body text-center">
                  <button className="btn btn-primary mb-0">Filter Results</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CourseList;
